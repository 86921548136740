import { css } from '@emotion/css';

export const optionItemIcon = css`
  display: inline-block;
  margin-right: 8px;
  visibility: hidden;
`;

export const optionItem = css`
  padding-left: 16px;
  padding-right: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid #f3f3f3;
  }
`;

export const selectedOptionItem = css`
  svg {
    visibility: visible;
  }
`;

export const popupWrapper = css`
  left: 0;
  margin-top: 8px;
  width: 150px !important;
  padding: 0;

  @media (min-width: 1025px) {
    margin-top: 16px;
  }
`;
