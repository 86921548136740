import React, { ReactNode } from 'react';

import cx from '../../utils/classnames';

import styles from './dialogContent.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
};

const DialogContent = (props: Props) => {
  const { className, children } = props;

  return (
    <div data-testid="dialog-content" className={cx(className, styles.content)}>
      {children}
    </div>
  );
};

export default DialogContent;
