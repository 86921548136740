import React, { useState, ReactNode } from 'react';

import { Attention, Close, Correct, Info } from '@travel/icons/ui';
import { cx } from '@travel/utils';

import stylesDefault from './alert.module.scss';
import stylesBalloon from './alertBalloon.module.scss';

type Props = {
  /** Style name to control appearance of this component from parent component*/
  className?: string;
  titleWrapperClassName?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  icon?: ReactNode;
  iconClassName?: string;
  /** the type of Alert Component, may display different color, background color and icon */
  type?: 'success' | 'error' | 'info' | 'warning';
  /** Title. bold font-weight */
  title?: ReactNode;
  /** Description */
  description?: ReactNode;
  /** Flag to show close button */
  isClosable?: boolean;
  /** Flag for validation error */
  isValidation?: boolean;
  /** Style variation for the content  */
  variation?: 'default' | 'balloon';
};

export const ERROR_ALERT_ID = 'error-alert';

export default function Alert(props: Props) {
  const {
    className,
    titleWrapperClassName,
    titleClassName,
    descriptionClassName,
    iconClassName,
    title,
    description,
    type = 'info',
    isClosable = true,
    isValidation = false,
    variation = 'default',
    ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(true);

  const styles = variation === 'balloon' ? stylesBalloon : stylesDefault;

  let icon: null | ReactNode = null;
  let errorAlertId = '';
  switch (type) {
    case 'success':
      icon = <Correct size={16} />;
      break;
    case 'error': {
      icon = <Attention size={16} />;
      errorAlertId = ERROR_ALERT_ID;
      break;
    }
    case 'info':
    case 'warning':
    default:
      icon = <Info size={16} />;
  }

  const onHandleOnClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={cx(styles.alertWrapper, styles[type], isOpen || styles.isClose, className)}
      data-alert-id={errorAlertId}
      data-testid="alert-wrapper"
      {...rest}
    >
      <div className={cx(styles.titleWrapper, titleWrapperClassName)}>
        <div className={cx(styles.icon, iconClassName)}>{props.icon || icon}</div>
        <div className={cx(styles.title, titleClassName, isValidation && styles.errorMessage)}>
          {title}
        </div>
      </div>
      {isClosable && <Close className={styles.closeButton} onClick={onHandleOnClose} size={15} />}
      {description && (
        <div className={cx(styles.description, descriptionClassName)}>{description}</div>
      )}
    </div>
  );
}
