import { h, Fragment } from 'preact';

import { ArrowRightLine, MoreVertical } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { Media } from '@travel/ui';
import { cx } from '@travel/utils';

import JourneyLegDate from 'components/JourneyLegDate';

import { ServiceType } from 'constants/reservations';
import { Itinerary } from 'ReservationsList-Types';
import { isNextDay } from 'utils/date';

import styles from './JourneyDate.module.scss';

export type Props = {
  itineraries: Array<Itinerary>;
  serviceType: ServiceType;
  busCourseCode?: string;
  busDepartureStationCode?: string;
};

const getTime = (time?: string) => {
  if (!time) return '';
  const singleDigitHourRegex = new RegExp(/^0{1}\d:\d{2}$/);
  if (singleDigitHourRegex.test(time)) {
    return time?.substring(1);
  } else {
    return time;
  }
};

const HotelJourney = (props: Props) => {
  const { itineraries, serviceType } = props;
  const itinerary = itineraries[0];

  const isDayUse = serviceType === ServiceType.DAY_USE;
  return (
    <div class={cx(styles.container, styles.hotelContainer)} data-testid="hotel-journey-component">
      <div class={styles.hotelDateContainer}>
        <JourneyLegDate
          date={itinerary.checkInDate}
          headerLabel={
            isDayUse ? (
              <Translate id="Booking_List.Reservation_Summary.Dayuse.Start_Date" />
            ) : (
              <Translate id="Booking_List.Reservation_Summary.Hotel.Start_Date" />
            )
          }
          isInline={isDayUse}
          wrapperClassName={cx(!isDayUse && styles.legContainerWidth)}
        >
          {getTime(itinerary.checkInTime)}
          {isDayUse ? ` - ${getTime(itinerary.checkOutTime)}` : ''}
        </JourneyLegDate>

        {!isDayUse && (
          <>
            <ArrowRightLine className={styles.icon} size={16} color="sonicSilver" />
            <JourneyLegDate
              date={itinerary.checkOutDate}
              headerLabel={<Translate id="Booking_List.Reservation_Summary.Hotel.End_Date" />}
            >
              {getTime(itinerary.checkOutTime)}
            </JourneyLegDate>
          </>
        )}
      </div>
      {!isDayUse && (
        <div class={styles.specialNoteContainer}>
          <Translate id="Booking_List.Reservation_Summary.Hotel.Special_Note" />
        </div>
      )}
    </div>
  );
};

const RentalCarJourney = (props: Props) => {
  const { itineraries } = props;
  const itinerary = itineraries[0];

  return (
    <div class={styles.container} data-testid="rent-a-car-journey-component">
      <JourneyLegDate
        date={itinerary.departureDate}
        headerLabel={<Translate id="Booking_List.Reservation_Summary.Cars.Start_Date" />}
        wrapperClassName={styles.legContainerWidth}
      >
        <p>{getTime(itinerary.departureTime)}</p>
        <p>{itinerary.departurePlace}</p>
      </JourneyLegDate>

      <ArrowRightLine className={styles.icon} size={16} color="sonicSilver" />

      <JourneyLegDate
        date={itinerary.arrivalDate}
        headerLabel={<Translate id="Booking_List.Reservation_Summary.Cars.End_Date" />}
        wrapperClassName={styles.legContainerWidth}
      >
        <p>{getTime(itinerary.arrivalTime)}</p>
        <p>{itinerary.arrivalPlace}</p>
      </JourneyLegDate>
    </div>
  );
};

const BusJourney = (props: Props) => {
  const { itineraries, busCourseCode, busDepartureStationCode } = props;
  const journey = itineraries[0];
  const isArrivedNextDay = isNextDay(`${journey.departureDate}`, `${journey.arrivalDate}`);

  return (
    <div
      class={cx(styles.container, !isArrivedNextDay && styles.alignBottom)}
      data-testid="bus-journey-component"
    >
      <JourneyLegDate
        date={journey.departureDate}
        headerLabel={<Translate id="Booking_List.Reservation_Summary.Bus.Boarding_Date" />}
        dateClassName={!isArrivedNextDay ? styles.sameDate : ''}
        wrapperClassName={styles.legContainerWidth}
      >
        <p>{getTime(journey.departureTime)}</p>
        <p>
          <a
            href={`https://bus.travel.rakuten.co.jp/bus/RouteMapMaviAction.do?buscode=${busDepartureStationCode}&isRide=true&courseCode=${busCourseCode}`}
            target="_blank"
            rel="noreferrer noopener"
            class={styles.busDepartureStation}
          >
            {journey.departurePlace}
          </a>
        </p>
      </JourneyLegDate>

      <ArrowRightLine
        className={cx(styles.icon, styles.bottomPosition)}
        size={16}
        color="sonicSilver"
      />

      <JourneyLegDate
        date={isArrivedNextDay ? journey.arrivalDate : undefined}
        headerLabel={isArrivedNextDay ? <Translate id="Booking_List.Get_Off_Date" /> : undefined}
        wrapperClassName={styles.legContainerWidth}
        isReserveHeaderHeight={!isArrivedNextDay}
      >
        <p>{getTime(journey.arrivalTime)}</p>
        <p>{journey.arrivalPlace}</p>
      </JourneyLegDate>
    </div>
  );
};

const OverseaAirJourney = (props: Props) => {
  const { itineraries, serviceType } = props;
  const isMoreThanThreeItineraries = itineraries.length > 3;
  const isRoundTrip = itineraries.length === 2;
  const headerLabels = [
    <Translate
      key="Booking_List.Reservation_Summary.Tour.Outward_Trip"
      id="Booking_List.Reservation_Summary.Tour.Outward_Trip"
    />,
    <Translate
      key="Booking_List.Reservation_Summary.Tour.Return_Trip"
      id="Booking_List.Reservation_Summary.Tour.Return_Trip"
    />,
  ];

  const displayedItineraries = itineraries.reduce(
    (selectedItineraries: Array<Itinerary>, itinerary, index) => {
      if (isMoreThanThreeItineraries && index >= 2 && index < itineraries.length - 1) {
        return selectedItineraries;
      } else {
        selectedItineraries.push(itinerary);
      }
      return selectedItineraries;
    },
    [],
  );

  const isDp = serviceType === ServiceType.OVERSEAS_DP;

  return (
    <div
      class={cx(styles.indirectContainer, isDp && styles.overseaDpDivider)}
      data-testid="oversea-journey-component"
    >
      {displayedItineraries.map((itinerary, index) => {
        return (
          <Fragment key={index}>
            <div class={styles.flightTitleBox}>
              <span class={styles.header}>{isRoundTrip ? headerLabels[index] : null}</span>

              <div class={styles.flightTitle}>
                {itinerary.flightName && (
                  <span class={styles.flightName}>{itinerary.flightName}</span>
                )}
                <Media
                  wrapperClassName={styles.legMediaWrapper}
                  className={styles.legMedia}
                  alt={serviceType}
                  url={itinerary.media?.url}
                />
              </div>
            </div>
            <div class={cx(styles.bottomPush, styles.container)}>
              <JourneyLegDate
                date={itinerary.departureDate}
                wrapperClassName={styles.legContainerWidth}
              >
                <p class={styles.departurePlace}>{itinerary.departurePlace}</p>
                <p>
                  {getTime(itinerary.departureTime)}
                  <span class={styles.subText}>{itinerary.departureAirport}</span>
                </p>
              </JourneyLegDate>

              <ArrowRightLine
                className={cx(styles.icon, styles.bottomPosition)}
                size={16}
                color="sonicSilver"
              />

              <JourneyLegDate
                date={itinerary.arrivalDate}
                wrapperClassName={styles.legContainerWidth}
              >
                <p class={styles.departurePlace}>{itinerary.arrivalPlace}</p>
                <p>
                  {getTime(itinerary.arrivalTime)}
                  <span class={styles.subText}>{itinerary.arrivalAirport}</span>
                </p>
              </JourneyLegDate>
            </div>

            {index === 1 && isMoreThanThreeItineraries ? (
              <div class={styles.separator}>
                <MoreVertical size={16} />
                {<Translate id="Booking_List.Reservation_Summary.Flight.Multiflight_Notes" />}
              </div>
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};

const PackageJourney = (props: Props) => {
  const { itineraries, serviceType } = props;
  const headerLabels = [
    <Translate
      key="Booking_List.Reservation_Summary.Tour.Outward_Trip"
      id="Booking_List.Reservation_Summary.Tour.Outward_Trip"
    />,
    <Translate
      key="Booking_List.Reservation_Summary.Tour.Return_Trip"
      id="Booking_List.Reservation_Summary.Tour.Return_Trip"
    />,
  ];
  const isJr = serviceType === ServiceType.JR;
  const isAirDP = serviceType === ServiceType.ANA || serviceType === ServiceType.JAL;

  return (
    <>
      <div class={cx(styles.bottomPush)} data-testid="package-journey-component">
        {itineraries.map((itinerary, index) => {
          const isArrivedNextDay =
            !isJr && isNextDay(`${itinerary.departureDate}`, `${itinerary.arrivalDate}`);

          return (
            <Fragment key={index}>
              <div
                class={cx(
                  styles.bottomPush,
                  styles.container,
                  !isArrivedNextDay && styles.alignBottom,
                )}
              >
                <JourneyLegDate
                  date={itinerary.departureDate}
                  headerLabel={headerLabels[index]}
                  dateClassName={!isArrivedNextDay ? styles.sameDate : ''}
                  wrapperClassName={styles.legContainerWidth}
                  isReserveHeaderHeight={false}
                >
                  {isJr ? <p class={styles.trainName}>{itinerary.trainName}</p> : null}
                  <p class={cx(styles.departurePlace, isJr && styles.departurePlaceWithTrainName)}>
                    {itinerary.departurePlace}
                  </p>
                  <p>{isAirDP ? null : getTime(itinerary.departureTime)}</p>
                </JourneyLegDate>

                <ArrowRightLine
                  className={cx(
                    styles.icon,
                    styles.bottomPosition,
                    isJr && styles.jrBottomPosition,
                  )}
                  size={16}
                  color="sonicSilver"
                />

                <JourneyLegDate
                  date={isArrivedNextDay ? itinerary.arrivalDate : undefined}
                  wrapperClassName={styles.legContainerWidth}
                  isReserveHeaderHeight
                >
                  <p class={cx(styles.departurePlace, isJr && styles.withTrainName)}>
                    {itinerary.arrivalPlace}
                  </p>
                  <p>{isAirDP ? null : getTime(itinerary.arrivalTime)}</p>
                </JourneyLegDate>
              </div>
            </Fragment>
          );
        })}
      </div>
      <div class={styles.packageDivider} />
    </>
  );
};

const JourneyDate = (props: Props) => {
  const { serviceType } = props;
  const SERVICE_DATE = {
    DOMESTIC: HotelJourney,
    DAY_USE: HotelJourney,
    ANA: PackageJourney,
    JAL: PackageJourney,
    JR: PackageJourney,
    RENTAL_CAR: RentalCarJourney,
    BUS: BusJourney,
    OVERSEAS_HOTEL: HotelJourney,
    OVERSEAS_AIR: OverseaAirJourney,
    OVERSEAS_DP: OverseaAirJourney,
  };
  const Component = SERVICE_DATE[serviceType];
  return <Component {...props} />;
};

export default JourneyDate;
