import { h, ComponentChild, ComponentChildren } from 'preact';

import { getCurrencySign, L10nMoney, L10nNumber } from '@travel/i18n';
import { Coupon, NoticeUser } from '@travel/icons/ui';
import { COLORS } from '@travel/icons/withIconProps';
import { Translate } from '@travel/translation';
import { RadioButton } from '@travel/ui';
import { cx } from '@travel/utils';

import { MobileCoupon, RemovingCoupon } from 'RakutenMobile-Types';

import { css } from '@emotion/css';
import styles from './listElement.module.scss';

const radioButton = css`
  display: flex;
  margin-bottom: 0;

  &:focus-within {
    box-shadow: none;
  }

  > input:checked ~ span {
    border-color: ${COLORS.cilantro};
  }
`;

type Props = {
  type: 'default' | 'selected' | 'warning';
  removedCoupon?: RemovingCoupon;
  coupon?: MobileCoupon;
  warningLabel?: ComponentChild;
  onClick?: (coupon: MobileCoupon) => void;
  currency?: string;
};

const CouponElement = (props: {
  coupon: MobileCoupon;
  isChecked: boolean;
  onClick?: (coupon: MobileCoupon) => void;
  currency?: string;
}) => {
  const { coupon, isChecked, onClick, currency = 'JPY' } = props;
  const {
    couponCode,
    discountPrice,
    discountedPrice,
    couponEndDate,
    couponName,
    notificationText,
  } = coupon;
  const currencySymbol = getCurrencySign(currency);

  return (
    <RadioButton
      value={couponCode}
      className={radioButton}
      isChecked={isChecked}
      onChange={() => {
        onClick?.(coupon);
      }}
      labelClassName={styles.couponLabel}
      label={
        <ul>
          <li class={cx(styles.bold, styles.smallBottomPush)}>
            <L10nMoney value={discountPrice} isJPYAlternative={false} />
            <Translate id="Booking_List.Mobile_Coupon.Coupon.Discounted" />
          </li>
          <li class={cx(styles.couponInfo, styles.truncated)}>{couponName}</li>
          <li class={cx(styles.couponInfo, styles.mediumBottomPush)}>
            <Translate id="Booking_List.Mobile_Coupon.Coupon.Total_Amount.After_Coupon_Apply" />
            <span class={styles.highlightedText}>
              <span class={cx(styles.highlightPrice)}>
                <L10nNumber value={discountedPrice} />
              </span>
              {currencySymbol}
              (<Translate id="Booking_List.Mobile_Coupon.Coupon.Tax_Included" />)
            </span>
          </li>
          <li class={styles.couponValidityDate}>
            <Translate
              id="Booking_List.Mobile_Coupon.Coupon.Apply_Deadline"
              data={{ apply_due_datetime: couponEndDate }}
            />
          </li>
          {notificationText && (
            <li class={cx(styles.highlightedText, styles.couponPriceWarning)}>
              {notificationText}
            </li>
          )}
        </ul>
      }
      name="mobileCoupon"
    />
  );
};

const WarningElement = (props: { children: ComponentChildren }) => {
  const { children } = props;
  return (
    <div class={styles.warningContainer}>
      <div class={styles.noticeUserContainer}>
        <NoticeUser color="chocolate" />
      </div>
      {children}
    </div>
  );
};

const ListElement = (props: Props) => {
  const { type, removedCoupon, coupon, warningLabel, onClick, currency } = props;
  return (
    <li data-testid="rakutenMobile-listElement" class={cx(styles.coupon, styles[type])}>
      {coupon ? (
        <CouponElement
          isChecked={type === 'selected'}
          coupon={coupon}
          onClick={onClick}
          currency={currency}
        />
      ) : warningLabel ? (
        <WarningElement children={warningLabel} />
      ) : (
        <WarningElement
          children={
            <div>
              <p>
                <Translate id="Booking_List.Mobile_Coupon.Notice.Combination_Use_NA" />
                <Translate
                  id="Booking_List.Mobile_Coupon.Notice.Combination_Use_NA.Delete"
                  className={styles.bold}
                />
              </p>
              <div class={styles.removedCouponContainer}>
                <div class={styles.couponContainer}>
                  <Coupon size={16} className={styles.removedCouponIcon} />
                  <span class={styles.truncated}>{removedCoupon?.couponName}</span>
                </div>
                <div class={styles.removedCouponPrice}>
                  -<L10nMoney value={removedCoupon?.discountPrice} isJPYAlternative={false} />
                </div>
                <hr class={styles.crossLine} />
              </div>
            </div>
          }
        />
      )}
    </li>
  );
};

export default ListElement;
