import { h } from 'preact';

import Banner from './components/Banner';
import FurusatoCouponSearchDialog from './components/FurusatoCouponSearchDialog';
import FurusatoErrorDialog from './components/FurusatoErrorDialog';
import MobileCouponSearchDialog from './components/MobileCouponSearchDialog';
import MobileErrorDialog from './components/MobileErrorDialog';
import OtherServices from './components/OtherServices';
import QRCodeDialogue from './components/QRCodeDialog';
import ReservationTab from './components/ReservationTab';

import { Query } from 'ReservationsList-Types';

type Props = {
  path: string;
  filter?: string;
  query: Query;
};

const ReservationList = (props: Props) => {
  return (
    <div data-testid="reservation-list">
      <Banner />
      <ReservationTab {...props} />
      <OtherServices />
      <QRCodeDialogue />
      <FurusatoCouponSearchDialog />
      <FurusatoErrorDialog />
      <MobileCouponSearchDialog />
      <MobileErrorDialog />
    </div>
  );
};

export default ReservationList;
