import { h } from 'preact';
import { useSelector } from 'react-redux';

import cx from '@travel/icons/_utils/classnames';
import { Translate } from '@travel/translation';
import { useDeviceType, DESKTOP_SCREEN } from '@travel/traveler-core/hooks';

import { getItem } from 'store/banner/selectors';

import styles from './banner.module.scss';

type Props = { className?: string };

function Banner(props: Props) {
  const isPC = useDeviceType() === DESKTOP_SCREEN;
  const item = useSelector(getItem);

  return (
    <div class={cx(props.className, styles.wrapper)} data-testid="banner-wrapper">
      <div class={styles.backgroundContainer} style={{ background: item.titleBackgroundColor }}>
        <div class={styles.contentContainer}>
          <p class={styles.title}>{<Translate id="Booking_List.Header.Page_Title" />}</p>
          <img
            src={isPC ? item.titleImagePc : item.titleImageSp}
            class={styles.image}
            alt="travel feeling"
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;
