import labels from 'labels';
import { h } from 'preact';

import { Translate } from '@travel/translation';
import { cx } from '@travel/utils';

import ReservationInfo from '../ReservationInfo';

import { YAKKAN_URL } from 'constants/common';

import styles from './bookingInformation.module.scss';

const paymentTypes = {
  CREDIT_CARD: <Translate id="MyPage_Enumeration.Payment_Method.Credit_Card" />,
  LOCAL: <Translate id="MyPage_Enumeration.Payment_Method.Pay_At_Local" />,
  CORPORATE_CHARGE: <Translate id="MyPage_Enumeration.Payment_Method.Corporate" />,
  ALLIANCE: <Translate id="MyPage_Enumeration.Payment_Method.Alliance" />,
};

const roomDetails = (numberOfAdults, numberOfChildren, numberOfRooms) => (
  <>
    <Translate
      id="Booking_List.Reservation_Summary.Room_Count"
      data={{ number_of_rooms: numberOfRooms }}
      className={styles.labelSpace}
    />
    (
    <Translate
      id="Booking_List.Reservation_Summary.Guest_Count.Adult"
      data={{ number_of_adults: numberOfAdults }}
    />
    {numberOfChildren > 0 && (
      <>
        、
        <Translate
          id="Booking_List.Reservation_Summary.Guest_Count.Child"
          data={{ number_of_children: numberOfChildren }}
        />
      </>
    )}
    )
  </>
);

const transportPassenger = (nbOfAdult, nbOfChildren) => (
  <>
    <Translate
      id="Booking_List.Reservation_Summary.Guest_Count.Adult"
      data={{ number_of_adults: nbOfAdult }}
    />
    {nbOfChildren > 0 && (
      <>
        、
        <Translate
          id="Booking_List.Reservation_Summary.Guest_Count.Child"
          data={{ number_of_children: nbOfChildren }}
        />
      </>
    )}
  </>
);

export const AdditionalInformationDomestic = ({ guestName, paymentMethod, reservationId }) => {
  return (
    <>
      <ReservationInfo
        key="reservationNumber"
        title={<Translate id="Booking_List.Reservation_Summary.Reservation_Number" />}
        content={
          <>
            {reservationId} (
            <a class={styles.link} href={YAKKAN_URL}>
              <Translate id="Booking_List.Reservation_Summary.Travel_Type.Arranged_Tour" />
            </a>
            )
          </>
        }
      />
      <ReservationInfo
        key="guestName"
        title={<Translate id="Booking_List.Reservation_Summary.Guest_Representative" />}
        content={guestName}
      />
      <ReservationInfo
        key="paymentMethod"
        title={<Translate id="Booking_List.Reservation_Summary.Payment_Method" />}
        content={paymentTypes[paymentMethod]}
      />
    </>
  );
};

export const AdditionalInformationCar = ({
  carBodyType,
  carClass,
  carEnterpriseClass,
  reservationId,
}) => {
  return (
    <>
      <ReservationInfo
        key="reservationNumber"
        title={<Translate id="Booking_List.Reservation_Summary.Reservation_Number" />}
        content={reservationId}
      />
      <ReservationInfo
        key="carType"
        title={<Translate id="Booking_List.Reservation_Summary.Cars.Type" />}
        content={`${carEnterpriseClass} ${carBodyType}/${carClass}`}
      />
    </>
  );
};

export const AdditionalInformationPackage = ({
  providerNameInPrimaryLang,
  noOfAdults,
  noOfChildren,
  noOfRooms,
  providerNameInSecondaryLang,
  reservationId,
  multipleProvider,
}: {
  providerNameInPrimaryLang: string;
  noOfAdults: number;
  noOfChildren: number;
  noOfRooms: number;
  reservationId: number;
  providerNameInSecondaryLang?: string | null;
  multipleProvider?: boolean;
}) => {
  return (
    <>
      <ReservationInfo
        className={cx(styles.isColumn, styles.isLast)}
        key="providerNameInPrimaryLang"
        title={<Translate id="Booking_List.Reservation_Summary.Accommodation" />}
        headerClassName={styles.providerNameHeader}
        content={
          <>
            <p class={styles.providerName}>{providerNameInPrimaryLang}</p>
            {providerNameInSecondaryLang && (
              <p class={styles.secondaryLang}>{providerNameInSecondaryLang}</p>
            )}
            {multipleProvider ? (
              <p class={styles.multipleProvider}>{labels.multipleProvider}</p>
            ) : null}
          </>
        }
      />
      <ReservationInfo
        key="reservationNumber"
        title={<Translate id="Booking_List.Reservation_Summary.Reservation_Number" />}
        content={<>{reservationId}</>}
      />
      <ReservationInfo
        key="roomInformation"
        title={<Translate id="Booking_List.Reservation_Summary.Number_Of_Rooms" />}
        content={roomDetails(noOfAdults, noOfChildren, noOfRooms)}
      />
    </>
  );
};

export const AdditionalInformationBus = ({ reservationId, noOfAdults, noOfChildren }) => {
  return (
    <>
      <ReservationInfo
        key="reservationNumber"
        title={<Translate id="Booking_List.Reservation_Summary.Reservation_Number" />}
        content={reservationId}
      />
      <ReservationInfo
        key="passengerInfo"
        title={<Translate id="Booking_List.Reservation_Summary.Number_Of_Guests" />}
        content={<>{transportPassenger(noOfAdults, noOfChildren)}</>}
      />
    </>
  );
};

export const AdditionalInformationOverseaAir = ({
  reservationId,
  classOfService,
  noOfAdults,
  noOfChildren,
}) => {
  return (
    <>
      <ReservationInfo
        key="reservationNumber"
        title={<Translate id="Booking_List.Reservation_Summary.Reservation_Number" />}
        content={reservationId}
        className={styles.topPush}
      />
      <ReservationInfo
        key="classOfServer"
        title={<Translate id="Booking_List.Booking_List.Reservation_Summary.Flight.Class" />}
        content={classOfService}
      />
      <ReservationInfo
        key="passengerInfo"
        title={<Translate id="Booking_List.Reservation_Summary.Number_Of_Guests" />}
        content={<>{transportPassenger(noOfAdults, noOfChildren)}</>}
      />
    </>
  );
};

export const AdditionalInformationOverseaHotel = ({ reservationId, guestName, paymentMethod }) => {
  return (
    <>
      <ReservationInfo
        key="reservationNumber"
        title={<Translate id="Booking_List.Reservation_Summary.Reservation_Number" />}
        content={
          <>
            {reservationId} (
            <a class={styles.link} href={YAKKAN_URL}>
              <Translate id="Booking_List.Reservation_Summary.Travel_Type.Arranged_Tour" />
            </a>
            )
          </>
        }
      />
      <ReservationInfo
        key="guestName"
        title={<Translate id="Booking_List.Reservation_Summary.Guest_Representative" />}
        content={guestName}
      />
      <ReservationInfo
        key="paymentMethod"
        title={<Translate id="Booking_List.Reservation_Summary.Payment_Method" />}
        content={paymentTypes[paymentMethod]}
      />
    </>
  );
};
