import { h } from 'preact';

import { useDeviceType, MOBILE_SCREEN } from '@travel/traveler-core/hooks';
import { Media } from '@travel/ui';

import ReservationTitle from 'components/ReservationTitle';

import { COMPANY_LOGO, ReservationStatusColor, ServiceType } from 'constants/reservations';
import { Media as MediaType } from 'ReservationsList-Types';

import styles from './reservationHeader.module.scss';

type Props = {
  serviceType: ServiceType;
  status: keyof typeof ReservationStatusColor;
  serviceName: string | null;
  media?: MediaType;
  secondaryName?: string;
  providerId?: string;
};

const ReservationHeader = (props: Props) => {
  const isSP = useDeviceType() === MOBILE_SCREEN;
  const { serviceType, status, serviceName, media, secondaryName, providerId } = props;
  const isOversSeaAir = serviceType === ServiceType.OVERSEAS_AIR;
  const isNotOverseasJourney =
    serviceType !== ServiceType.OVERSEAS_AIR && serviceType !== ServiceType.OVERSEAS_DP;

  const ServiceIcon = COMPANY_LOGO[serviceType];
  const shouldDisplaySecondaryName =
    serviceType === ServiceType.OVERSEAS_HOTEL || serviceType === ServiceType.DOMESTIC;

  const isHotel =
    serviceType === ServiceType.DOMESTIC ||
    serviceType === ServiceType.DAY_USE ||
    serviceType === ServiceType.OVERSEAS_HOTEL;

  const title = isHotel ? (
    <a
      class={styles.link}
      href={`https://travel.rakuten.co.jp/HOTEL/${providerId}/${providerId}.html`}
    >
      {serviceName}
    </a>
  ) : (
    serviceName
  );
  return (
    <div class={styles.container}>
      <ReservationTitle
        className={styles.reservationTitle}
        serviceType={serviceType}
        status={status}
      />

      <div class={styles.reservationTitleRow}>
        {!isOversSeaAir && (
          <div class={styles.reservationTitleBox}>
            {serviceName && (
              <h2 data-testid="reservationHeader-primary-name" class={styles.title}>
                {title}
              </h2>
            )}
            {shouldDisplaySecondaryName && secondaryName && (
              <h3 class={styles.secondaryTitle} data-testid="reservationHeader-secondary-name">
                {secondaryName}
              </h3>
            )}
          </div>
        )}

        {isSP &&
          isNotOverseasJourney &&
          (ServiceIcon ? (
            <ServiceIcon className={styles.headerMedia} height="35" width="96" />
          ) : (
            <Media url={media?.url} alt="Reservation photo" className={styles.headerMedia} />
          ))}
      </div>
    </div>
  );
};

export default ReservationHeader;
