import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { Translate } from '@travel/translation';
import { Media } from '@travel/ui';

import { Dialog, DialogHeader } from 'components/CustomDialog';

import { closeQrDialog } from 'store/reservationsList/actions';

import { getIsQrDialogOpen, getQrCodeReservationId } from 'store/reservationsList/selectors';

import styles from './qrCodeDialog.module.scss';

const QRCodeDialogue = () => {
  const dispatch = useDispatch();
  const reservationId = useSelector(getQrCodeReservationId);
  const isOpen = useSelector(getIsQrDialogOpen);
  const handleCloseDialog = useCallback(() => {
    dispatch(closeQrDialog());
  }, [dispatch]);
  return (
    <Dialog
      isOpen={isOpen}
      contentClassName={styles.dialogContent}
      className={styles.dialogContainer}
    >
      <DialogHeader
        title={<Translate id="Booking_List.Reservation_Summary.Navigation.Checkin_With_QR" />}
        onClickIcon={handleCloseDialog}
      />
      <div class={styles.container}>
        <Media
          url={`https://rsvh.travel.rakuten.co.jp/mypage/generateQrCode.do?data=${reservationId}`}
          className={styles.qrCode}
        />
        <p class={styles.reservationId}>{reservationId}</p>
      </div>
    </Dialog>
  );
};

export default QRCodeDialogue;
