import { h } from 'preact';

import { ArrowRight } from '@travel/icons/ui';
import { Translate } from '@travel/translation';

import styles from './otherServices.module.scss';

type Props = { className?: string };

const LINKS = [
  {
    text: <Translate id="Booking_List.Footer.Other_Alliance.Title" />,
    url: 'https://travel.rakuten.co.jp/share/themes/mypage/alliance/index.html',
  },
  {
    text: <Translate id="Booking_List.Footer.Page_Notice.Title" />,
    url: 'https://travel.faq.rakuten.net/detail/000035076',
  },
];

function OtherServices(props: Props) {
  return (
    <div class={props.className}>
      <div class={styles.contentBox}>
        <p>
          <Translate id="Booking_List.Footer.RTX.Explanation" />
        </p>

        <div class={styles.modificationBox}>
          <img
            class={styles.rtxImage}
            src="https://trvimg.r10s.jp/share/images/personal/RTX_logo.png"
            alt="RTX"
          />
          <a
            href="https://reservations.travel.rakuten.net/login"
            class={styles.link}
            target="_blank"
            rel="noreferrer nofollow"
          >
            <Translate id="Booking_List.Footer.RTX.Title" />
          </a>
        </div>
      </div>

      <nav class={styles.navBox}>
        {LINKS.map(link => (
          <a
            key={link.text}
            href={link.url}
            class={styles.navLink}
            target="_blank"
            rel="noreferrer nofollow"
          >
            {link.text} <ArrowRight size={16} />
          </a>
        ))}
      </nav>
    </div>
  );
}

export default OtherServices;
