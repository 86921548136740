import React from 'react';

import { cx } from '@travel/utils';

import styles from './flatButton.module.scss';

type Props = {
  classType: string;
  isLoading?: boolean;
};

export default function LoadingCircle(props: Props) {
  return (
    <svg
      data-testid="loading-circle"
      className={cx(styles.circular, props.isLoading && styles.loading)}
      viewBox="0 0 72 72"
    >
      <path d="M36 0C16.118 0 0 16.118 0 36s16.118 36 36 36 36-16.118 36-36S55.882 0 36 0m0 10c14.336 0 26 11.664 26 26S50.336 62 36 62 10 50.336 10 36s11.664-26 26-26" />
      <path
        className={props.classType === 'primary' ? styles.loadingPrimary : styles.loadingSecondary}
        d="M36 0v10c14.336 0 26 11.664 26 26h10C72 16.118 55.882 0 36 0"
      />
    </svg>
  );
}
