import React, { KeyboardEvent, ReactNode } from 'react';

import { cx } from '@travel/utils';

import { Tab } from './index';

import styles from './navigationTab.module.scss';

type Props = {
  /** Current selected tab object */
  tab: {
    id: string;
    title: ReactNode;
  };
  /** Current selected tab index*/
  selectedTabIndex: number;
  /** Should be array of object with keys id, title and content with values respectively */
  tabs: Array<Tab>;
  /** Custom className for the Button */
  tabButtonClassName?: string;
  /** Custom className for the inner button */
  innerButtonClassName?: string;
  /** Custom className for the selected inner button */
  selectedInnerButtonClassName?: string;
  /** if the current button is selected */
  isSelected: boolean;
  /** callback function on click */
  onClickTab: (event: React.MouseEvent) => void;
  /** */
  onKeyPressed: (newTabId?: string) => void;
};

const TabButton = React.forwardRef<HTMLButtonElement, Props>(function TabButton(props: Props, ref) {
  const {
    tab,
    tabs,
    tabButtonClassName,
    innerButtonClassName,
    selectedInnerButtonClassName,
    isSelected,
    selectedTabIndex,
    onClickTab,
  } = props;

  const onKeyPressed = (e: KeyboardEvent<HTMLButtonElement>) => {
    let newTab = tabs[selectedTabIndex];
    switch (e.key) {
      case 'ArrowLeft':
        e.preventDefault();
        newTab = tabs[selectedTabIndex - 1];
        props.onKeyPressed(newTab?.id);
        return;
      case 'ArrowRight':
        e.preventDefault();
        newTab = tabs[selectedTabIndex + 1];
        props.onKeyPressed(newTab?.id);
        return;
      case 'Enter':
        e.preventDefault();
        const selectedTabId = e.currentTarget.id;
        props.onKeyPressed(selectedTabId);
        return;
    }
  };

  return (
    <div key={tab.id} className={cx(styles.buttonWrapper, tabButtonClassName)}>
      <button
        data-testid={`navtab-button-${tab.id}`}
        role="tab"
        type="button"
        className={cx(
          isSelected && cx(styles.selectedTab, selectedInnerButtonClassName),
          styles.tab,
          innerButtonClassName,
        )}
        aria-selected={isSelected}
        aria-controls={tab.id}
        id={tab.id}
        onClick={onClickTab}
        onKeyDown={onKeyPressed}
        tabIndex={isSelected ? 0 : -1}
        ref={ref}
      >
        {tab.title}
      </button>
    </div>
  );
});

export default TabButton;
