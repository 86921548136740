// NOTE: This Animation component purpose is ONLY to make a showcase for the mixins animation been used, not for any component usage under Aloha project.
import React, { ReactNode, useRef } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

import styles from './animation.module.scss';

export type TimeOut = {
  enter: number;
  exit: number;
};

export type AnimationDuration = 'short' | 'long' | 'animation';

export type AnimationType =
  | 'fadeIn'
  | 'slideUp'
  | 'slideInRight'
  | 'fadeInDown'
  | 'shake'
  | 'zoomIn';

export type Props = {
  classNames?: string;
  wrapperClassName?: string;
  /** When this becomes true, element will appear*/
  isAppear: boolean;

  /** When this becomes true, element will disappear*/
  isExit: boolean;

  /** When this becomes true, transition starts*/
  isStartAction: boolean;

  /** Timeout for entering and exiting of the animation*/
  timeout: TimeOut;

  /** Types of the animation available*/
  type: AnimationType;

  /** Types of basic animation*/
  animationDuration: AnimationDuration;

  /** Reference to make a animation between the min and max threshold*/
  children: ReactNode;

  /** This will be called right after startAction becomes true*/
  onEnter: () => void;

  /** This will be called after onEnter*/
  onEntered: () => void;

  /** This will be called after startAction becomes false*/
  onExited: () => void;
};

export default function Animation(props: Props) {
  const {
    isAppear,
    timeout,
    isStartAction,
    children,
    onEntered,
    onExited,
    onEnter,
    isExit,
    type,
    animationDuration,
    wrapperClassName,
    ...rest
  } = props;

  const nodeRef = useRef<null>(null);

  const classNames = {
    appear: styles[`${type}Appear`],
    appearActive: styles[`${type}AppearActive${animationDuration}`],
    enter: styles[`${type}Enter`],
    enterActive: styles[`${type}EnterActive${animationDuration}`],
    exit: styles[`${type}Exit`],
    exitActive: styles[`${type}ExitActive${animationDuration}`],
  };

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isStartAction}
      appear={isAppear}
      exit={isExit}
      timeout={timeout}
      classNames={Object.keys(classNames).length === 0 ? rest.classNames : classNames}
      onEntered={onEntered}
      onExited={onExited}
      onEnter={onEnter}
      unmountOnExit
      {...rest}
    >
      {/*extra div needed by CSSTransition React component, since it is expecting a element for a return value*/}
      <div ref={nodeRef} className={wrapperClassName}>
        {children}
      </div>
    </CSSTransition>
  );
}

Animation.defaultProps = {
  isAppear: false,
  isExit: true,
  isStartAction: true,
  timeout: {
    enter: 300,
    exit: 300,
  },
  type: 'fadeIn',
  animationDuration: 'long',
  children: undefined,
  onExited: () => {},
  onEnter: () => {},
  onEntered: () => {},
};
