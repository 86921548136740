type Action = { type: string; key: string; value: any } | null;

// TODO try to remove any
const reducer = (initialState: any, action: Action) => {
  const state = { ...initialState };
  if (!action || !action.type) {
    return state;
  }

  switch (action.type) {
    case 'SET_CONTAINER_WIDTH':
    case 'SET_LEFT':
    case 'SET_SELECTED_TAB':
    case 'SET_ORIGINAL_OFFSET':
    case 'SET_TOUCH_START_X':
    case 'SET_DIRECTION':
    case 'SET_PREVIOUS_TOUCH_X':
    case 'SET_BEING_TOUCHED':
    case 'SET_SELECTED_PANEL':
    case 'SET_CHANGED_OFFSET': {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
