import { h } from 'preact';
import { ReactElement } from 'react';

import { Window } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { useDeviceType, DESKTOP_SCREEN } from '@travel/traveler-core/hooks';
import { FlatButton, IconTextLink } from '@travel/ui';
import { as } from '@travel/utils';

import styles from './JRReservationDetailButton.module.scss';
import env from '@travel/env';

type Props = {
  className?: string;
  externalId?: string;
  reservationId?: string;
};

const JRReservationDetailButton = (props: Props) => {
  const { className, externalId, reservationId } = props;

  const deviceType = useDeviceType();
  const isPc = deviceType === DESKTOP_SCREEN;
  const detailUrl = env('UNIVERSAL_JRDP_DETAIL_URL');

  return (
    <form action={detailUrl} data-testid={`quickAction-link-detail`} method="post">
      {isPc ? (
        <>
          <FlatButton
            type="submit"
            className={className}
            icon={<Window className={styles.JrIcon} size={24} color="white" />}
          >
            <Translate id="Booking_List.Reservation_Summary.Navigation.Details" />
          </FlatButton>
          <span className={styles.jRDpExtraText}>
            (<Translate id="Booking_List.Reservation_Summary.Navigation.JRDP.Detail" />)
          </span>
        </>
      ) : (
        <button class={styles.invisibleSubmitButton} type="submit">
          <IconTextLink
            className={className}
            iconPosition="right"
            hoveredColor="blueRaspberry"
            unhoveredColor="blueRaspberry"
            text={
              <>
                <Translate id="Booking_List.Reservation_Summary.Navigation.Details" />
                <span class={styles.jRdPReservationDetailLabel}>
                  (
                  <Translate id="Booking_List.Reservation_Summary.Navigation.JRDP.Detail" />)
                </span>
              </>
            }
            icon={as<ReactElement>(<Window size={14} />)}
          />
        </button>
      )}

      <input type="hidden" name="EXTERNAL_ID" value={externalId} />
      <input type="hidden" name="TRAVEL_NUMBER" value={reservationId} />
      <input type="hidden" name="TYPE" value="TypeR" />
    </form>
  );
};

export default JRReservationDetailButton;
