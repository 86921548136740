import { h } from 'preact';
import { useState } from 'preact/hooks';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { ArrowRight } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import NavigationTab from '@travel/traveler-core/components/NavigationTab';
import { useDeviceTypes } from '@travel/traveler-core/hooks';
import { as } from '@travel/utils';

import DataLayer from 'components/DataLayer';
import { Query } from 'ReservationsList-Types';
import { fetchReservationList } from 'store/reservationsList/actions';
import ReservationList from '../ReservationList';

import { ServiceType, Status } from 'constants/reservations';

import styles from './reservationTab.module.scss';

export const TABS = {
  UPCOMING: {
    id: Status.UPCOMING,
    title: <Translate id="Booking_List.Header.List_Indicator.Upcoming" />,
    pageName: 'booking_list_current',
  },
  PAST: {
    id: Status.PAST,
    title: <Translate id="Booking_List.Header.List_Indicator.Past" />,
    pageName: 'booking_list_past',
  },
  CANCELED: {
    id: Status.CANCELED,
    title: <Translate id="Booking_List.Header.List_Indicator.Canceled" />,
    pageName: 'booking_list_canceled',
  },
};

type Props = { className?: string; filter?: string; query: Query };

function ReservationTab(props: Props) {
  const {
    query: { period, serviceType, reservationList },
  } = props;
  const formattedServiceType = serviceType ? as<Array<ServiceType>>(serviceType.split(',')) : [];
  const dispatch = useDispatch();
  const isPc = useDeviceTypes().isPC;
  const [currentTab, setCurrentTab] = useState(reservationList || TABS.UPCOMING.id);
  const handleTabsChange = (event: ChangeEvent) => {
    const reservationList = event.currentTarget.id as Status;
    if (currentTab !== reservationList) {
      dispatch(
        fetchReservationList({
          reservationList,
          serviceType: formattedServiceType,
          period: period,
          offset: 0,
          limit: 10,
        }),
      );
      setCurrentTab(reservationList);
    }
  };

  const ratPage = TABS[currentTab]?.pageName || TABS.UPCOMING.pageName;

  return (
    <>
      <NavigationTab
        isAnimatedOnStart={false}
        hasTouchEvents={false}
        isSwipePanel={false}
        selectedTab={reservationList || TABS.UPCOMING.id}
        className={props.className}
        tabMenuClassName={styles.tabMenu}
        tabButtonClassName={styles.tabButton}
        innerButtonClassName={styles.tabInnerButton}
        selectedInnerButtonClassName={styles.selectedTabInnerButton}
        onClick={handleTabsChange}
        additionalTabContent={
          isPc && (
            <a
              href="https://aps1.travel.rakuten.co.jp/portal/my/prv_page.top
        "
              class={styles.oldPageRedirection}
              data-testid="reservationTab-redirection-link"
            >
              <Translate id="Booking_List.Header.Old_Mypage_link" />
              <ArrowRight color="darkCyan" size={16} className={styles.oldPageRedirectionIcon} />
            </a>
          )
        }
        tabs={[
          {
            ...TABS.UPCOMING,
            content: (
              <ReservationList
                queryPeriod={period}
                querySelectedService={formattedServiceType}
                selectedTab={TABS.UPCOMING.id}
              />
            ),
          },
          {
            ...TABS.PAST,
            content: (
              <ReservationList
                queryPeriod={period}
                querySelectedService={formattedServiceType}
                selectedTab={TABS.PAST.id}
              />
            ),
          },
          {
            ...TABS.CANCELED,
            content: (
              <ReservationList
                queryPeriod={period}
                querySelectedService={formattedServiceType}
                selectedTab={TABS.CANCELED.id}
              />
            ),
          },
        ]}
      />
      <DataLayer page={ratPage} />
    </>
  );
}

export default ReservationTab;
