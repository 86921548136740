import { h } from 'preact';
import { ReactElement } from 'react';

import ArrowRight from '@travel/icons/ui/ArrowRight';
import { Translate } from '@travel/translation';
import useDeviceType, {
  DESKTOP_SCREEN,
  MOBILE_SCREEN,
} from '@travel/traveler-core/hooks/useDeviceType';
import { IconTextLink, Media } from '@travel/ui';
import { as, cx } from '@travel/utils';

import JourneyDate from 'components/JourneyDate';
import { bookingDetails } from 'utils/quickActionsUrl';
import {
  AdditionalInformationBus,
  AdditionalInformationCar,
  AdditionalInformationDomestic,
  AdditionalInformationOverseaAir,
  AdditionalInformationOverseaHotel,
  AdditionalInformationPackage,
} from '../BookingInformations';
import FurusatoNoseiButton from '../FurusatoNoseiButton';
import JRReservationDetailButton from '../JRReservationDetailButton';
import Notification from '../Notification';
import QuickActions from '../QuickActions';
import RakutenMobileButton from '../RakutenMobileButton';
import ReservationHeader from '../ReservationHeader';

import {
  CouponStatus,
  COMPANY_LOGO,
  ReservationStatusColor,
  ServiceType,
} from 'constants/reservations';
import { Reservation } from 'ReservationsList-Types';

import styles from './reservationCard.module.scss';

type Props = {
  reservation: Reservation;
};

const reservationInfos = {
  DOMESTIC: AdditionalInformationDomestic,
  DAY_USE: AdditionalInformationDomestic,
  ANA: AdditionalInformationPackage,
  JAL: AdditionalInformationPackage,
  JR: AdditionalInformationPackage,
  RENTAL_CAR: AdditionalInformationCar,
  BUS: AdditionalInformationBus,
  OVERSEAS_HOTEL: AdditionalInformationOverseaHotel,
  OVERSEAS_AIR: AdditionalInformationOverseaAir,
  OVERSEAS_DP: AdditionalInformationPackage,
};

const ReservationCard = (props: Props) => {
  const deviceType = useDeviceType();
  const isPC = deviceType === DESKTOP_SCREEN;
  const isSP = deviceType === MOBILE_SCREEN;

  const {
    reservation: {
      reservationId,
      itineraries,
      serviceType,
      status,
      route,
      quickActions,
      paymentMethod,
      guestName,
      carBodyType,
      carClass,
      carEnterpriseClass,
      providerNameInSecondaryLang,
      noOfAdults,
      noOfChildren,
      noOfRooms,
      providerNameInPrimaryLang,
      multipleProvider,
      reservedSkyrepNo,
      providerId,
      busCourseCode,
      itemId,
      notifications,
      paymentDeadlineDate,
      hometownTaxCouponStatus,
      mobileCouponStatus,
      qrCodeId,
      busDepartureStationCode,
      rmpUrl,
      targetUrl,
      externalId,
    },
  } = props;

  const ReservationInfo = reservationInfos[serviceType];

  const classOfServices = itineraries.every(i => i.classOfService)
    ? itineraries.reduce((classes: Array<string>, itinerary) => {
        if (itinerary.classOfService && !classes.includes(itinerary.classOfService)) {
          classes.push(itinerary.classOfService);
        }
        return classes;
      }, [])
    : [];

  const reservationInfoProps = {
    reservationId,
    paymentMethod,
    guestName,
    carBodyType,
    carClass,
    carEnterpriseClass,
    providerNameInSecondaryLang,
    noOfAdults,
    noOfChildren,
    noOfRooms,
    providerNameInPrimaryLang,
    multipleProvider,
    classOfService: classOfServices.join('/'),
  };

  const quickActionProps = {
    reservationId,
    serviceType,
    reservedSkyrepNo,
    providerId,
    busCourseCode,
    itemId,
    checkInTime: itineraries[0].checkInTime,
    checkInDate: itineraries[0].checkInDate,
    checkOutDate: itineraries[0].checkOutDate,
    qrCodeId,
    rmpUrl,
    targetUrl,
    externalId,
  };

  const media = itineraries[0].media;
  const ServiceIcon = COMPANY_LOGO[serviceType];
  const isJr = serviceType === ServiceType.JR;
  const hasNotification =
    notifications && Object.values(notifications).some(notification => !!notification);

  const isNotOverseasJourney =
    serviceType !== ServiceType.OVERSEAS_AIR && serviceType !== ServiceType.OVERSEAS_DP;
  return (
    <div class={styles.container}>
      {!isSP && (
        <div className={styles.reservationMedia}>
          {isNotOverseasJourney &&
            (ServiceIcon ? (
              <ServiceIcon
                data-testid="reservationCard-pc-media"
                className={styles.media}
                width="152"
                height="55"
              />
            ) : (
              <Media
                data-testid="reservationCard-pc-media"
                url={media?.url}
                alt="Reservation photo"
                className={styles.media}
              />
            ))}
        </div>
      )}

      <section class={styles.contentContainer}>
        <ReservationHeader
          media={media}
          serviceType={serviceType}
          serviceName={route || providerNameInPrimaryLang || null}
          status={as<keyof typeof ReservationStatusColor>(status)}
          secondaryName={providerNameInSecondaryLang}
          providerId={providerId}
        />
        <JourneyDate
          itineraries={itineraries}
          serviceType={serviceType}
          busCourseCode={busCourseCode}
          busDepartureStationCode={busDepartureStationCode}
        />
        <ReservationInfo {...reservationInfoProps} />
        {!isPC &&
          (isJr ? (
            <JRReservationDetailButton
              className={styles.detailLink}
              externalId={externalId}
              reservationId={reservationId}
            />
          ) : (
            <IconTextLink
              className={styles.detailLink}
              iconPosition="right"
              hoveredColor="blueRaspberry"
              unhoveredColor="blueRaspberry"
              text={
                <>
                  <Translate id="Booking_List.Reservation_Summary.Navigation.Details" />
                </>
              }
              onClick={() => {
                window.location.href = `${bookingDetails({
                  serviceType,
                  reservationId,
                  providerId,
                  checkInDate: itineraries[0].checkInDate,
                  checkOutDate: itineraries[0].checkOutDate,
                  reservedSkyrepNo,
                })}`;
              }}
              icon={as<ReactElement>(<ArrowRight size={14} />)}
            />
          ))}
        {hometownTaxCouponStatus && hometownTaxCouponStatus !== CouponStatus.NOT_APPLICABLE ? (
          <div class={cx(styles.furusatoNoseiSection, hasNotification && styles.omitBottomSpacing)}>
            <FurusatoNoseiButton
              hometownTaxCouponStatus={hometownTaxCouponStatus}
              reservationId={reservationId}
            />
          </div>
        ) : null}
        {mobileCouponStatus && mobileCouponStatus !== CouponStatus.NOT_APPLICABLE ? (
          <div class={cx(styles.rakutenMobileSection, hasNotification && styles.omitBottomSpacing)}>
            <RakutenMobileButton
              mobileCouponStatus={mobileCouponStatus}
              reservationId={reservationId}
            />
          </div>
        ) : null}
      </section>
      {hasNotification && (
        <section class={styles.notificationSection}>
          <Notification notifications={notifications} paymentDeadlineDate={paymentDeadlineDate} />
        </section>
      )}
      <section class={styles.actionContainer}>
        <QuickActions quickActions={quickActions} reservationDetails={quickActionProps} />
      </section>
    </div>
  );
};

export default ReservationCard;
