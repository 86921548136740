import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import { FurusatoNozeiState } from './reducer';

import { CouponSearchResponseBody } from 'FurusatoNozei-Types';

export const getFurusatoNozei = (state: RootState) => state.furusatoNozei;

export const getIsCouponDialogOpen = createSelector(
  getFurusatoNozei,
  (state: FurusatoNozeiState) => state.isCouponSelectionDialogOpen,
);

const getCouponListState = createSelector(
  getFurusatoNozei,
  (state: FurusatoNozeiState) => state.couponList,
);

export const getCouponList = createSelector(
  getCouponListState,
  (couponList: CouponSearchResponseBody) => couponList.selectionCoupons,
);

export const getCurrentPrice = createSelector(
  getCouponListState,
  (couponList: CouponSearchResponseBody) => couponList.currentBookingPrice,
);

export const getRemovedCoupon = createSelector(
  getCouponListState,
  (couponList: CouponSearchResponseBody) => couponList.removingCoupon,
);

export const getIsSubmitFailed = createSelector(
  getFurusatoNozei,
  (state: FurusatoNozeiState) => state.isSubmitCouponFailedDialogOpen,
);

export const getIsFetchingCouponList = createSelector(
  getFurusatoNozei,
  (state: FurusatoNozeiState) => state.isFetchingCouponList,
);

export const getIsSubmittingCoupon = createSelector(
  getFurusatoNozei,
  (state: FurusatoNozeiState) => state.isSubmittingCoupon,
);

export const getErrorMessage = createSelector(
  getFurusatoNozei,
  (state: FurusatoNozeiState) => state.furusatoErrorMessage,
);
