import { ServiceType } from 'constants/reservations';

export type UrlParameter = {
  reservationId?: string;
  providerId?: string;
  busCourseCode?: string;
  serviceType: ServiceType;
  reservedSkyrepNo?: string;
  itemId?: string;
  checkInTime?: string;
  checkInDate?: string;
  checkOutDate?: string;
  qrCodeId?: string;
  rmpUrl?: string;
  targetUrl?: string;
  externalId?: string;
};

export const bookingDetails = (parameter: UrlParameter) => {
  const {
    serviceType,
    reservationId,
    providerId,
    checkInDate,
    checkOutDate,
    reservedSkyrepNo,
  } = parameter;

  return {
    DOMESTIC: `https://aps1.travel.rakuten.co.jp/portal/my/ry_kakunin.syosai?f_flg=&f_no=${providerId}&f_syu=&f_yoyaku_no=${reservationId}&f_tomari=${checkInDate}&f_kaeri=${checkOutDate}&f_uketuke_date=&f_yoyaku_flg=ry`,
    DAY_USE: `https://aps1.travel.rakuten.co.jp/portal/my/ry_kakunin.syosai?f_flg=&f_no=${providerId}&f_syu=&f_yoyaku_no=${reservationId}&f_tomari=${checkInDate}&f_kaeri=${checkInDate}&f_uketuke_date=&f_yoyaku_flg=du`,
    ANA: `https://package.travel.rakuten.co.jp/anarsv/app/p/gfp110/init?cdDpYoyakuKanri=${reservationId}`,
    JAL: `https://dp.travel.rakuten.co.jp/jal_frt/reserveRef/?cdDpYoyakuKanri=${reservationId}`,
    RENTAL_CAR: `https://booking.cars.travel.rakuten.co.jp/cars/rcf080a.do?ycd=${reservationId}`,
    BUS: `https://bus.travel.rakuten.co.jp/bus/MemberSupportAction.do?f_rcd=${reservationId}&action=TO_DETAIL`,
    OVERSEAS_HOTEL: `https://rsvh.travel.rakuten.co.jp/orsv/confirmTransition.do?f_flg=&f_no=${providerId}&f_syu=&f_yoyaku_no=${reservationId}&f_tomari=${checkInDate}&f_kaeri=${checkOutDate}&f_uketuke_date=&f_yoyaku_flg=ry&confirm=予約詳細`,
    OVERSEAS_DP: `https://overseas.travel.rakuten.co.jp/dynamic/MyBookingDetailAction.do?bookingNo=${reservationId}`,
    OVERSEAS_AIR: `https://overseas.travel.rakuten.co.jp/airticket/UserMyPageDetailAction.do?bookingNo=${reservationId}&reservedSkyrepNo=${reservedSkyrepNo}`,
  }[serviceType];
};

export const cancel = (parameter: UrlParameter) => {
  const { serviceType, reservationId, providerId, checkInDate, checkOutDate, itemId } = parameter;

  return {
    DOMESTIC: `https://aps1.travel.rakuten.co.jp/portal/my/ry_kakunin.syosai?f_flg=cancel&f_no=${providerId}&f_syu=${itemId}&f_yoyaku_no=${reservationId}&f_tomari=${checkInDate}&f_kaeri=${checkOutDate}&f_uketuke_date=&f_yoyaku_flg=ry`,
    DAY_USE: `https://aps1.travel.rakuten.co.jp/portal/my/ry_kakunin.syosai?f_flg=cancel&f_no=${providerId}&f_syu=${itemId}&f_yoyaku_no=${reservationId}&f_tomari=${checkInDate}&f_kaeri=${checkInDate}&f_uketuke_date=&f_yoyaku_flg=du`,
    ANA: `https://package.travel.rakuten.co.jp/anarsv/app/p/gfp110/init?cdDpYoyakuKanri=${reservationId}`,
    JAL: `https://dp.travel.rakuten.co.jp/jal_frt/reserveRef/?cdDpYoyakuKanri=${reservationId}`,
    RENTAL_CAR: `https://booking.cars.travel.rakuten.co.jp/cars/rcf081a.do?ycd=${reservationId}`,
    BUS: `https://bus.travel.rakuten.co.jp/bus/MemberSupportAction.do?f_rcd=${reservationId}&action=TO_DETAIL`,
    OVERSEAS_DP: `https://overseas.travel.rakuten.co.jp/dynamic/MyBookingDetailAction.do?bookingNo=${reservationId}`,
    OVERSEAS_HOTEL: `https://rsvh.travel.rakuten.co.jp/orsv/confirmTransition.do?f_flg=cancel&f_no=${providerId}&f_syu=&f_yoyaku_no=${reservationId}&f_tomari=${checkInDate}&f_kaeri=${checkOutDate}&f_uketuke_date=&f_yoyaku_flg=ry&confirm=キャンセル手続きへ`,
    OVERSEAS_AIR: `https://overseas.travel.rakuten.co.jp/airticket/BookedCancelFeeAction.do?bookingNo=${reservationId}`,
  }[serviceType];
};

export const shortenDate = (parameter: UrlParameter) => {
  const {
    providerId,
    reservationId,
    checkInDate,
    checkInTime,
    checkOutDate,
    serviceType,
    itemId,
  } = parameter;
  return {
    DOMESTIC: `https://aps1.travel.rakuten.co.jp/portal/my/ry_kakunin.syosai?f_flg=cut&f_no=${providerId}&f_syu=${itemId}&f_yoyaku_no=${reservationId}&f_tomari=${checkInDate}&f_kaeri=${checkOutDate}&f_uketuke_date=&f_ck_in=${checkInTime}&f_yoyaku_flg=ry`,
    OVERSEAS_HOTEL: `https://rsvh.travel.rakuten.co.jp/orsv/confirmTransition.do?f_flg=cut&f_no=${providerId}&f_syu=&f_yoyaku_no=${reservationId}&f_tomari=${checkInDate}&f_kaeri=${checkOutDate}&f_uketuke_date=&f_yoyaku_flg=ry&confirm=日程短縮`,
  }[serviceType];
};

export const changePaymentMethod = (parameter: UrlParameter) =>
  `https://rsvh.travel.rakuten.co.jp/mypage/my/changePayment.do?yoyakuNo=${parameter.reservationId}`;

export const issueReceipt = (parameter: UrlParameter) => {
  const { reservationId, serviceType } = parameter;
  return {
    DOMESTIC: `https://rsvh.travel.rakuten.co.jp/mypage/receipt/issue.do?reservationId=${reservationId}&serviceId=DOMESTIC`,
    DAY_USE: `https://rsvh.travel.rakuten.co.jp/mypage/receipt/issue.do?reservationId=${reservationId}&serviceId=DAY_USE`,
    BUS: `https://bus.travel.rakuten.co.jp/bus/ReceiptAction.do?f_rcd=${reservationId}`,
    OVERSEAS_HOTEL: `https://rsvh.travel.rakuten.co.jp/mypage/receipt/issue.do?reservationId=${reservationId}&serviceId=OVERSEAS_HOTEL`,
    OVERSEAS_DP: `https://rsvh.travel.rakuten.co.jp/mypage/receipt/issue.do?reservationId=${reservationId}&serviceId=OVERSEAS_DP`,
    OVERSEAS_AIR: `https://rsvh.travel.rakuten.co.jp/mypage/receipt/issue.do?reservationId=${reservationId}&serviceId=OVERSEAS_AIR`,
    RENTAL_CAR: `https://booking.cars.travel.rakuten.co.jp/cars/rcf082a.do?ycd=${reservationId}&step=1`,
    ANA: `https://rsvh.travel.rakuten.co.jp/mypage/receipt/issue.do?reservationId=${reservationId}&serviceId=ANA`,
    JAL: `https://rsvh.travel.rakuten.co.jp/mypage/receipt/issue.do?reservationId=${reservationId}&serviceId=JAL`,
  }[serviceType];
};

export const qrCodeCheckIn = (parameter: UrlParameter) =>
  `https://aps1.travel.rakuten.co.jp/portal/my/prv_page.first?f_tab=1#TB_inline?height=400&width=400&inlineId=${parameter.reservationId}_qrcode`;

export const mobilQrCode = (parameter: UrlParameter) =>
  `https://bus.travel.rakuten.co.jp/bus/ShowETicketAction.do?reservationId=${parameter.reservationId}`;

export const postReview = (parameter: UrlParameter) => {
  const { providerId, serviceType, busCourseCode } = parameter;

  return {
    DOMESTIC: `https://aps1.travel.rakuten.co.jp/portal/my/toukou.toukou_bunki?f_hotel_no=${providerId}`,
    BUS: `https://bus.travel.rakuten.co.jp/bus/BusContributionAction.do?f_course=${busCourseCode}`,
    OVERSEAS_HOTEL: `https://aps1.travel.rakuten.co.jp/portal/my/toukou.toukou_bunki?f_hotel_no=${providerId}`,
  }[serviceType];
};

export const editReview = (parameter: UrlParameter) => {
  const { serviceType, busCourseCode } = parameter;
  return {
    DOMESTIC: 'https://aps1.travel.rakuten.co.jp/portal/my/toukou.henkou',
    BUS: `https://bus.travel.rakuten.co.jp/bus/GuestContributionEditAction.do?f_course=${busCourseCode}`,
    OVERSEAS_HOTEL: 'https://aps1.travel.rakuten.co.jp/portal/my/toukou.henkou',
  }[serviceType];
};

export const bookAgain = (parameter: UrlParameter) => {
  const { providerId, serviceType } = parameter;
  return {
    DOMESTIC: `https://hotel.travel.rakuten.co.jp/hotelinfo/plan/${providerId}`,
    DAY_USE: `https://hotel.travel.rakuten.co.jp/hotelinfo/plan/${providerId}`,
    RENTAL_CAR: `https://cars.travel.rakuten.co.jp/cars/rcf130a.do?v=1&eid=${providerId}`,
    OVERSEAS_HOTEL: `https://hotel.travel.rakuten.co.jp/hotelinfo/plan/${providerId}`,
  }[serviceType];
};

export const settlePayment = (parameter: UrlParameter) => {
  const { serviceType, reservationId, reservedSkyrepNo } = parameter;

  return {
    ANA: `https://package.travel.rakuten.co.jp/anarsv/app/p/gfp110/init?cdDpYoyakuKanri=${reservationId}`,
    JAL: `https://dp.travel.rakuten.co.jp/jal_frt/reserveRef/?cdDpYoyakuKanri=${reservationId}`,
    OVERSEAS_DP: `https://overseas.travel.rakuten.co.jp/dynamic/AfterSettlementBookingAction.do?bookingNo=${reservationId}`,
    OVERSEAS_AIR: `https://overseas.travel.rakuten.co.jp/airticket/UserMyPageDetailAction.do?bookingNo=${reservationId}&reservedSkyrepNo=${reservedSkyrepNo}`,
  }[serviceType];
};

export const voucher = (parameter: UrlParameter) =>
  `https://rsvh.travel.rakuten.co.jp/orsv/voucher.do?f_yoyaku_no=${parameter.reservationId}`;

export const rmp = (parameter: UrlParameter) =>
  `${parameter.rmpUrl}login?serviceId=1003&serviceAttribute=106&recipientId=${parameter.providerId}&categoryId=2&targetId=${parameter.reservationId}&targetName=予約番号&targetUrl=${parameter.targetUrl}%3Ff_no=${parameter.providerId}%26f_yoyaku_no=${parameter.reservationId}`;
