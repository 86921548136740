import React, { useEffect, useRef, useState } from 'react';
import { FixedSizeList as ReactWindowList } from 'react-window';

import { Close } from '@travel/icons/ui';
import { ariaOnKeyDown, cx } from '@travel/utils';

import Dialog from '../Dialog';
import Popup from '../Popup';
import Skeleton from '../Skeleton';

import styles from './styledSelectBox.module.scss';

export type OptionItem = {
  displayedText?: React.ReactNode;
  text: React.ReactNode;
  value: string;
  icon?: React.ReactNode;
};

export type Props<T> = {
  /** Custom style for wrapper */
  className?: string;
  /** Custom style for wrapper only on open */
  openClassName?: string;
  /** custom data-test id */
  testId?: string;
  /** Custom style for displaying placeholder text */
  placeholderClassName?: string;
  /** Custom style for displaying value box wrapper displaying on viewport */
  displayBoxClassName?: string;
  /** Custom style for displaying value box wrapper displaying on viewport content */
  displayBoxContentClassName?: string;
  /** Custom style for absolute popup/dialog wrapper */
  listClassName?: string;
  /** Custom style for absolute popup/dialog outer wrapper */
  listWrapperClassName?: string;
  /** Custom style for absolute dialog content wrapper */
  listContentClassName?: string;
  /** Number to specify the height of popup in pixel (to use the react-window) */
  popupHeight?: number;
  /** Node of dropdown icon */
  dropdownIcon?: React.ReactNode;
  /** Node of dialog OK label (Primary button) */
  dialogOKLabel?: React.ReactNode;
  /** Node of dialog title label  */
  dialogTitleLabel?: React.ReactNode;
  /** Number to specify the height of each option item in pixel */
  optionItemHeight?: number;
  /** Custom style for each option item */
  optionItemClassName?: string;
  /** Custom style for selected option item */
  optionItemSelectedClassName?: string;
  /** Array of select box option items */
  options?: Array<T>;
  /** Flag to define whether the popup will be displayed on init or not */
  isOpen: boolean;
  /** String to define custom style for phone input options */
  customStyle?: 'popup' | 'dialog' | string;
  /** Callback to be called when selectBox value has been updated */
  onChange?: (option?: T) => void;
  /** Flag to define whether the search behavior is enabled or not */
  isSearchable?: boolean;
  /** Custom style for search box */
  searchBoxClassName?: string;
  /** Custom style for search input */
  searchInputClassName?: string;
  /** Node of search icon on search input */
  searchInputIcon?: React.ReactNode;
  /** String to define placeholder of the search box */
  searchInputPlaceholder?: string;
  /** when only it has title */
  popUpTitle?: React.ReactNode;
  /** when only it has description for title */
  popUpTitleDescription?: string;
  /** Custom style for displaying title wrapper */
  popUpTitleWrapperClassName?: string;
  /** Custom style for displaying title content */
  popUpTitleClassName?: string;
  /** Custom style for displaying title description */
  popUpTitleDescriptionClassName?: string;
  /** Boolean to hide close button if popupTitle is present */
  shouldHidePopupTitleCloseButton?: boolean;
  /** Function to be called when dialog or popup state is changed */
  onAppearanceChanged?: (isOpen: boolean) => void;
  /** Icon to display for selected option */
  selectedIcon?: React.ReactNode;
  /** Boolean to manage loading state */
  isLoading?: boolean;
  /** Show the icon for the selected value */
  isSelectedDisplayIcon?: boolean;
} & Omit<React.HTMLProps<HTMLSelectElement>, 'onChange' | 'label'>;

const DEFAULT_POPUP_LIST_HEIGHT = 1000;
const DEFAULT_OPTION_ITEM_HEIGHT = 40;

type ListProps<T> = {
  className?: string;
  /** Callback to be called when selectBox value has been updated */
  onChange?: (option?: T) => void;
  /** Callback to be called when search box has been updated */
  onSearch?: (event: React.FormEvent<HTMLInputElement>) => void;
  /** Number to define max height of contry list */
  listHeight?: number;
} & Pick<
  Props<T>,
  | 'options'
  | 'value'
  | 'customStyle'
  | 'optionItemSelectedClassName'
  | 'optionItemClassName'
  | 'optionItemHeight'
  | 'isSearchable'
  | 'searchBoxClassName'
  | 'searchInputClassName'
  | 'searchInputIcon'
  | 'searchInputPlaceholder'
  | 'selectedIcon'
>;

function List<T extends OptionItem>(props: ListProps<T>) {
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);
  const [searchElement, setSearchElement] = useState<HTMLDivElement | null>(null);
  const { options, selectedIcon } = props;
  const rowHeight = props.optionItemHeight || DEFAULT_OPTION_ITEM_HEIGHT;
  const itemsBasedHeight = (options?.length || 0) * rowHeight;
  const autoHeight =
    props.customStyle === 'popup'
      ? itemsBasedHeight
      : containerElement?.clientHeight ?? DEFAULT_POPUP_LIST_HEIGHT;

  return (
    <div
      className={cx(styles.listContainer, props.className)}
      ref={node => {
        // using container height if the prop is not set
        if (!props.listHeight && node) setContainerElement(node);
      }}
    >
      {props.isSearchable && (
        <div
          data-testid="styledSelectBox-search-input"
          className={cx(styles.searchBox, props.searchBoxClassName)}
          ref={node => {
            if (node) setSearchElement(node);
          }}
        >
          {props.searchInputIcon || null}
          <input
            className={cx(styles.searchInput, props.searchInputClassName)}
            type="text"
            onInput={props.onSearch}
            placeholder={props.searchInputPlaceholder}
          />
        </div>
      )}
      <ReactWindowList
        className={styles.list}
        itemCount={options?.length || 0}
        height={(props.listHeight ?? autoHeight) - (searchElement?.clientHeight || 0)}
        width="100%"
        itemSize={rowHeight}
      >
        {({ index, style }) => {
          const option = options?.[index];
          const isSelected = option?.value === props.value;

          return (
            <div
              key={index}
              style={style}
              className={cx(
                isSelected && props.optionItemSelectedClassName,
                styles.optionItem,
                props.optionItemClassName,
              )}
              onClick={() => props.onChange?.(option)}
              data-testid={`optionItem-${option?.value || `option_${index}`}-div`}
              tabIndex={0}
              onKeyDown={ariaOnKeyDown(() => props.onChange?.(option))}
            >
              {selectedIcon && isSelected ? selectedIcon : option?.icon}
              {option?.text}
            </div>
          );
        }}
      </ReactWindowList>
    </div>
  );
}

/**
 * NOTE:
 * - Controlled component
 * - This is a plain component with no specific styling. Please pass all styling from parent
 * */
function StyledSelectBox<T extends OptionItem>(props: Props<T>) {
  const wrapperRef = useRef<HTMLDivElement | null>();
  const filteredRef = useRef<{ [key: string]: Array<T> }>({});
  const [displayedOptions, setDisplayedOptions] = useState(props.options);
  const {
    isLoading = false,
    disabled = false,
    onAppearanceChanged,
    options,
    isSelectedDisplayIcon = true,
  } = props;
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const onToggle = () => {
    if (!disabled) setIsOpen(prev => !prev);
  };

  const selectedOption = options?.find(option => option.value === props.value);

  // reset search state after confirm selected
  const onChange = (option?: T) => {
    props.onChange?.(option);
    setDisplayedOptions(options);

    if (props.customStyle === 'popup') setIsOpen(false);
  };

  // being used for dialog style which contains the OK button
  const [pendingSelected, setPendingSelected] = useState(selectedOption);
  const onSelectPending = (option?: T) => setPendingSelected(option);

  const onConfirmPending = () => {
    setIsOpen(false);
    onChange?.(pendingSelected);
  };

  //Update list value when the options in the props are updated
  useEffect(() => {
    setDisplayedOptions(options);
  }, [options]);

  // appearance changed
  useEffect(() => {
    onAppearanceChanged?.(isOpen);
  }, [isOpen, onAppearanceChanged]);

  const onSearch = (event: React.FormEvent<HTMLInputElement>) => {
    const search = event.currentTarget?.value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&') || '';

    if (!search) setDisplayedOptions(options);
    if (filteredRef.current[search]) setDisplayedOptions(filteredRef.current[search]);

    const prevSearchOptions = filteredRef.current[search.slice(0, search.length - 1)]; // to reduce possible options to be filtered
    const matches = (prevSearchOptions || options).filter(opt => {
      if (search) return typeof opt.text === 'string' && opt.text?.match(new RegExp(search, 'gi'));
      return true;
    });

    filteredRef.current[search] = matches;
    setDisplayedOptions(matches);
  };

  const searchProps = props.isSearchable && {
    isSearchable: props.isSearchable,
    onSearch: onSearch,
    searchBoxClassName: props.searchBoxClassName,
    searchInputIcon: props.searchInputIcon,
    searchInputClassName: props.searchInputClassName,
    searchInputPlaceholder: props.searchInputPlaceholder,
  };

  const stylesProps = {
    customStyle: props.customStyle,
    optionItemClassName: props.optionItemClassName,
    optionItemSelectedClassName: props.optionItemSelectedClassName,
    optionItemHeight: props.optionItemHeight,
  };

  return (
    <div
      className={cx(styles.wrapper, props.className, isOpen && props.openClassName)}
      data-testid={`styledSelectBox-wrapper-${props.name}`}
    >
      <input type="hidden" name={props.name} value={props.value || ''} />
      <div
        className={cx(styles.displayBox, props.displayBoxClassName)}
        onClick={onToggle}
        role="button"
        data-testid={`styledSelectBox-${props.testId || props.name || 'toggle'}-button`}
        tabIndex={0}
        onKeyDown={ariaOnKeyDown(onToggle)}
      >
        <span
          className={cx(
            styles.displayBoxContent,
            props.displayBoxContentClassName,
            !Boolean(selectedOption?.text) && props.placeholderClassName,
          )}
        >
          {isLoading ? (
            <Skeleton width="100px" height="20px" row={1} />
          ) : (
            <>
              {isSelectedDisplayIcon ? selectedOption?.icon || props.selectedIcon || null : null}
              {selectedOption?.displayedText || selectedOption?.text || props.placeholder}
            </>
          )}
        </span>
        {props.dropdownIcon}
      </div>

      {/* Popup */}
      {props.customStyle === 'popup' && (
        <Popup
          className={cx(props.listClassName, styles.popup)}
          isOpen={isOpen}
          anchorEl={wrapperRef.current}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          {props.popUpTitle && (
            <div className={props.popUpTitleWrapperClassName}>
              {!props.shouldHidePopupTitleCloseButton && (
                <Close onClick={onToggle} size={13} className={styles.closeButton} />
              )}
              <span className={props.popUpTitleClassName}>{props.popUpTitle}</span>
              <span className={props.popUpTitleDescriptionClassName}>
                {props.popUpTitleDescription}
              </span>
            </div>
          )}
          <List
            options={displayedOptions}
            onChange={onChange}
            listHeight={props.popupHeight}
            value={props.value}
            {...stylesProps}
            {...searchProps}
            selectedIcon={props.selectedIcon}
          />
        </Popup>
      )}

      {/* dialog */}
      {props.customStyle === 'dialog' && (
        <Dialog
          title={props.dialogTitleLabel}
          isOpen={isOpen}
          className={props.listClassName}
          contentClassName={props.listContentClassName}
          wrapperClassName={props.listWrapperClassName}
          isUseContentFlexBox={false}
          onClose={() => {
            setPendingSelected(selectedOption);
            setIsOpen(false);
          }}
          buttonLabelPrimary={props.dialogOKLabel}
          onClickPrimary={onConfirmPending}
        >
          <List
            options={displayedOptions}
            onChange={onSelectPending}
            value={pendingSelected?.value}
            {...stylesProps}
            {...searchProps}
          />
        </Dialog>
      )}
    </div>
  );
}

StyledSelectBox.defaultProps = {
  isOpen: false,
  dropdownIcon: null,
  customStyle: 'popup',
  dialogOKLabel: 'OK',
  dialogTitleLabel: 'Title',
};

export default StyledSelectBox;
