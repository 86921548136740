import { h } from 'preact';

import styles from './emptyState.module.scss';

type Props = { name: string };

function EmptyFilteredListIcon(props: Props) {
  const pathId1 = `paint0_linear_117_24102-${props.name}`;
  const pathId2 = `paint1_linear_117_24102-${props.name}`;
  const pathId3 = `paint2_radial_117_24102-${props.name}`;
  const pathId4 = `paint3_radial_117_24102-${props.name}`;

  return (
    <svg
      data-testid="emptyState-emptyFilteredList-icon"
      class={styles.icon}
      width="294"
      height="107"
      viewBox="0 0 294 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M264.4 44.8V20.8C264.4 19.472 263.328 18.4 262 18.4H230.8C229.472 18.4 228.4 19.472 228.4 20.8V33.6C228.4 34.928 227.328 36 226 36H203.6C202.272 36 201.2 34.928 201.2 33.6V26.4C201.2 25.072 200.128 24 198.8 24H165.2C163.872 24 162.8 25.072 162.8 26.4V89.6C162.8 90.928 163.872 92 165.2 92H201.2H228.4H264.4H289.2C290.528 92 291.6 90.928 291.6 89.6V49.6C291.6 48.272 290.528 47.2 289.2 47.2H266.8C265.472 47.2 264.4 46.128 264.4 44.8Z"
        fill={`url(#${pathId1})`}
      />
      <path
        d="M92.3999 7.20001H70.7999C69.4719 7.20001 68.3999 8.27201 68.3999 9.60001V36.8C68.3999 38.128 67.3279 39.2 65.9999 39.2H44.3999C43.0719 39.2 41.9999 38.128 41.9999 36.8V25.856C41.9999 24.528 40.9279 23.456 39.5999 23.456H2.7999C1.4719 23.456 0.399902 24.528 0.399902 25.856V89.056C0.399902 90.384 1.4719 91.456 2.7999 91.456H41.9999V92H68.3999H94.7999H117.944C119.272 92 120.344 90.928 120.344 89.6V34.4C120.344 33.072 119.272 32 117.944 32H97.1999C95.8719 32 94.7999 30.928 94.7999 29.6V9.60001C94.7999 8.27201 93.7279 7.20001 92.3999 7.20001Z"
        fill={`url(#${pathId2})`}
      />
      <path
        d="M146 106.736C177.812 106.736 203.6 103.72 203.6 100C203.6 96.2798 177.812 93.264 146 93.264C114.188 93.264 88.3999 96.2798 88.3999 100C88.3999 103.72 114.188 106.736 146 106.736Z"
        fill={`url(#${pathId3})`}
      />
      <path
        d="M18.2881 2.92004V5.25604"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.2881 12.584V14.92"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.288 8.92004H22.272"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.3041 8.92004H12.2881"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M286.288 18.12V20.456"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M286.288 27.784V30.12"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M292.288 24.12H290.272"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M282.304 24.12H280.288"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M81.4878 39.72V42.056"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M81.4878 49.384V51.72"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M87.4879 45.72H85.4639"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.504 45.72H75.48"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.6882 75.32C48.2346 75.32 49.4882 74.0664 49.4882 72.52C49.4882 70.9736 48.2346 69.72 46.6882 69.72C45.1418 69.72 43.8882 70.9736 43.8882 72.52C43.8882 74.0664 45.1418 75.32 46.6882 75.32Z"
        stroke="#DCEAD2"
        stroke-width="3"
      />
      <path
        d="M255.488 58.52C257.034 58.52 258.288 57.2664 258.288 55.72C258.288 54.1736 257.034 52.92 255.488 52.92C253.942 52.92 252.688 54.1736 252.688 55.72C252.688 57.2664 253.942 58.52 255.488 58.52Z"
        stroke="#DCEAD2"
        stroke-width="3"
      />
      <path
        d="M226.688 8.92001C228.235 8.92001 229.488 7.6664 229.488 6.12001C229.488 4.57361 228.235 3.32001 226.688 3.32001C225.142 3.32001 223.888 4.57361 223.888 6.12001C223.888 7.6664 225.142 8.92001 226.688 8.92001Z"
        stroke="#DCEAD2"
        stroke-width="3"
      />
      <path
        d="M164.816 80H112.016C110.688 80 109.616 78.928 109.616 77.6V2.4C109.616 1.072 110.688 0 112.016 0H148.816C150.144 0 151.216 1.072 151.216 2.4V13.6C151.216 14.928 152.288 16 153.616 16H164.816C166.144 16 167.216 17.072 167.216 18.4V77.6C167.216 78.928 166.144 80 164.816 80Z"
        fill="white"
      />
      <path
        d="M166.512 16.704L165.808 16H164.816C165.432 16 166.04 16.232 166.512 16.704Z"
        fill="#F2F2F2"
      />
      <path
        d="M150.512 0.703979C150.984 1.17598 151.216 1.78398 151.216 2.39998V1.40798L150.512 0.703979Z"
        fill="#F2F2F2"
      />
      <path
        d="M151.216 2.40002V13.6C151.216 14.928 152.288 16 153.616 16H164.816H165.808L151.216 1.40802V2.40002Z"
        fill="#E5E5E5"
      />
      <path
        d="M177.768 29.192C168.848 20.272 154.384 20.272 145.472 29.192C136.552 38.112 136.552 52.576 145.472 61.488C154.392 70.4 168.856 70.408 177.768 61.488C186.688 52.576 186.688 38.112 177.768 29.192ZM173.76 57.48C167.056 64.184 156.184 64.184 149.48 57.48C142.776 50.776 142.776 39.904 149.48 33.2C156.184 26.496 167.056 26.496 173.76 33.2C180.464 39.904 180.464 50.776 173.76 57.48Z"
        fill="#B3CF9B"
      />
      <path
        d="M178.736 56.216L172.488 62.464L188.232 78.208C189.168 79.144 190.688 79.144 191.624 78.208L194.472 75.36C195.408 74.424 195.408 72.904 194.472 71.968L178.736 56.216Z"
        fill="#B3CF9B"
      />
      <path
        d="M144.384 45.296C144.384 54.808 152.096 62.52 161.608 62.52C164.256 62.52 166.76 61.904 169.008 60.832C170.184 56.472 171.072 51.344 171.072 45.744C171.072 39.832 170.08 34.32 168.8 29.664C166.608 28.656 164.176 28.072 161.608 28.072C152.096 28.072 144.384 35.784 144.384 45.296Z"
        fill="white"
      />
      <path
        d="M161.608 62.52C171.12 62.52 178.832 54.8086 178.832 45.296C178.832 35.7835 171.12 28.072 161.608 28.072C152.095 28.072 144.384 35.7835 144.384 45.296C144.384 54.8086 152.095 62.52 161.608 62.52Z"
        fill={`url(#${pathId4})`}
      />
      <defs>
        <linearGradient
          id={pathId1}
          x1="227.2"
          y1="16.7619"
          x2="227.2"
          y2="90.0676"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2718" stop-color="#E9E9E9" />
          <stop offset="0.8994" stop-color="#E9E9E9" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id={pathId2}
          x1="60.3721"
          y1="5.31257"
          x2="60.3721"
          y2="89.7736"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2718" stop-color="#E9E9E9" />
          <stop offset="0.8994" stop-color="#E9E9E9" stop-opacity="0" />
        </linearGradient>
        <radialGradient
          id={pathId3}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(146.004 99.9262) scale(57.4347 5.95954)"
        >
          <stop offset="0.3318" stop-color="#DDDDDD" />
          <stop offset="0.9007" stop-color="#DDDDDD" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id={pathId4}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(161.601 45.285) rotate(-45) scale(17.2222)"
        >
          <stop offset="0.5" stop-color="#DDDDDD" stop-opacity="0" />
          <stop offset="1" stop-color="#CCCCCC" stop-opacity="0.5" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default EmptyFilteredListIcon;
