import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import weekdays from 'dayjs/plugin/weekday';

import { useLocaleWeekAndMonth } from '@travel/i18n';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(customParseFormat);

dayjs.extend(duration);

dayjs.extend(weekdays);

dayjs.extend(isSameOrAfter);

type TimeDuration = {
  days: number;
  hours: number;
  minutes: number;
};

export const convert = (date: string) => dayjs(date);

export const getDate = (date: string) => convert(date).format('YYYY-MM-DD');
export const getTime = (date: string) => convert(date).format('HH:mm');
export const format = (date: string, format: string, locale?: string) =>
  convert(date)
    .locale(locale ?? 'ja')
    .format(format);

export const formatTime = (time: string, format: string) => dayjs(time, format).format(format);

export const YEAR_MONTH_KANJI_DATE = 'YYYY年M月';
export const YEAR_MONTH_DAY_KANJI_DATE = 'YYYY年M月D日';
export const MONTH_DAY_KANJI_DATE = 'M月D日';
export const JUST_WEEKDAY_PARENTHESES = '(ddd)';
export const DATE_WITH_SLASHES_TRUNCATED_ZEROS_FORMAT = 'l';
export const FULL_DATE_WITH_DASHES = 'YYYY-MM-DD';
export const FULL_DATE_WITH_SLASHES = 'YYYY/MM/DD';
export const FULL_DATE_YOUBI_FORMAT = 'YYYY年MM月DD日(ddd)';
export const FULL_DATE_YOUBI_FORMAT_WITH_SPACE = 'YYYY年MM月DD日 (ddd)';
export const FULL_DATE_YOUBI_FORMAT_TRUNCATED_ZEROES = 'YYYY年M月D日(ddd)';
export const FULL_DATE_YOUBI_FORMAT_TRUNCATED_ZEROES_WITH_SPACE = 'YYYY年M月D日 (ddd) ';

export const SHORT_DATE_YOUBI_FORMAT = 'MM月DD日(ddd)';
export const SHORT_DATE_YOUBI_FORMAT_TRUNCATED_ZEROES = 'M月D日(ddd)';
export const MONTH_DAY_YOUBI_WITH_SPACE_FORMAT = 'M月D日 (ddd)';
export const JAPANESE_MONTH_UNIT = '月';
export const JAPANESE_DAY_UNIT = '日';
export const HOURS_AND_MINUTES = 'HH:mm';

export const getTotalDuration = (dateA: string, dateB: string) => {
  const timeDuration = dayjs.duration(convert(dateB).diff(dateA));

  return {
    days: timeDuration.get('days'),
    hours: timeDuration.get('hours'),
    minutes: timeDuration.get('minutes'),
  } as TimeDuration;
};

export const getTotalDurationJourney = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
};

export const isTimeAfterOrEqual = (
  timeA: string,
  timeB: string,
  dateA: string = '2000-01-01',
  dateB: string = '2000-01-01',
) => {
  const firstDate = dayjs(`${dateA} ${timeA}`);
  const secondDate = dayjs(`${dateB} ${timeB}`);

  return secondDate.isSameOrAfter(firstDate);
};

export const getSplittedDate = (date: string) => {
  const dayjsDate = dayjs(date);
  const { weekdaysShort } = useLocaleWeekAndMonth();

  return {
    date: dayjsDate.date(),
    month: dayjsDate.month() + 1,
    day: weekdaysShort[dayjsDate.weekday()],
  };
};
