import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { More } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { useDeviceType, DESKTOP_SCREEN } from '@travel/traveler-core/hooks';
import useDialogHandler from '@travel/traveler-core/hooks/useDialogHandler';
import { Balloon } from '@travel/ui';
import { cx, isNotEmptyArray } from '@travel/utils';

import { QuickActions as QuickActionsType } from 'ReservationsList-Types';
import { openQrDialog } from 'store/reservationsList/actions';
import { formatQuickActions } from 'utils/quickActions';
import { bookingDetails, UrlParameter } from 'utils/quickActionsUrl';
import JRReservationDetailButton from '../JRReservationDetailButton';

import { ServiceType } from 'constants/reservations';

import styles from './quickActions.module.scss';

type Props = {
  quickActions: QuickActionsType;
  reservationDetails: UrlParameter;
};

const QuickActions = (props: Props) => {
  const { quickActions, reservationDetails } = props;

  const { isOpen, onOpen, onClose } = useDialogHandler(false);

  const deviceType = useDeviceType();
  const isPc = deviceType === DESKTOP_SCREEN;
  const isJR = reservationDetails.serviceType === ServiceType.JR;
  const dispatch = useDispatch();
  const formattedActions = formatQuickActions(quickActions, reservationDetails, deviceType);
  const isShowOthersMenu = formattedActions.length >= 5;
  const lengthClass = `action-${isShowOthersMenu ? 4 : formattedActions.length}`;
  const handleDialogue = (event: MouseEvent) => {
    event.preventDefault();
    dispatch(openQrDialog(reservationDetails.qrCodeId || ''));
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onClose);
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('click', onClose);
      }
    };
  }, [isOpen, onClose]);

  if (!isPc && !isNotEmptyArray(formattedActions)) return null;

  return (
    <div class={styles.container}>
      {isPc &&
        (isJR ? (
          <JRReservationDetailButton
            className={styles.jrDetailButton}
            externalId={reservationDetails.externalId}
            reservationId={reservationDetails.reservationId}
          />
        ) : (
          <>
            <a
              class={cx(styles[lengthClass], styles.detail)}
              key="detail"
              href={bookingDetails({
                serviceType: reservationDetails.serviceType,
                reservationId: reservationDetails.reservationId,
                providerId: reservationDetails.providerId,
                checkInDate: reservationDetails.checkInDate,
                checkOutDate: reservationDetails.checkOutDate,
                reservedSkyrepNo: reservationDetails.reservedSkyrepNo,
              })}
              target={'_self'}
              data-testid={`quickAction-link-detail`}
            >
              <div class={styles.actionText}>
                <Translate id="Booking_List.Reservation_Summary.Navigation.Details" />
              </div>
            </a>
          </>
        ))}
      {formattedActions.map(
        ({ url, icon, key, text, isNewTab, isQrCode }, index) =>
          (isPc || (!isPc && !isShowOthersMenu) || (!isPc && isShowOthersMenu && index < 3)) && (
            <a
              class={cx(styles[lengthClass], styles[key])}
              key={key}
              href={url}
              target={isNewTab ? '_blank' : '_self'}
              rel={`noreferrer noopener`}
              onClick={isQrCode ? handleDialogue : undefined}
              data-testid={`quickAction-link-${key}`}
            >
              <div class={cx(key === 'qrCheckIn' ? styles.showIcon : '', styles.iconContainer)}>
                {icon}
              </div>
              <div class={styles.actionText}>{text}</div>
            </a>
          ),
      )}
      {!isPc && isShowOthersMenu && (
        <button
          class={cx(styles[lengthClass], styles.otherButton)}
          rel={`noreferrer noopener`}
          onClick={onOpen}
          data-testid="quickAction-link-others"
        >
          <div class={styles.iconContainer}>
            <More color="cilantro" size={32} />
          </div>
          <div class={styles.actionText}>
            <Translate id="Booking_List.Reservation_Summary.Navigation.Others" />
          </div>
          {isOpen && (
            <Balloon
              direction="bottomRight"
              hasContentMinHeight={false}
              className={styles.balloon}
              triangleClassName={styles.triangle}
            >
              <ul className={styles.content}>
                {formattedActions?.map(({ url, icon, key, text, isNewTab, isQrCode }, index) => (
                  <li class={styles.actionLink} key={key} data-testid={`quickAction-link-${key}`}>
                    <a
                      key={key}
                      href={url}
                      target={isNewTab ? '_blank' : '_self'}
                      rel={`noreferrer noopener`}
                      onClick={isQrCode ? handleDialogue : undefined}
                      data-testid={`quickAction-link-${key}`}
                    >
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </Balloon>
          )}
        </button>
      )}
    </div>
  );
};

export default QuickActions;
