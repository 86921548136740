import React, { forwardRef, ChangeEvent, ReactNode } from 'react';

import cx from '../../utils/classnames';

import styles from './radioButton.module.scss';

export type Props = {
  className?: string;
  isCheckedClassName?: string;
  inputProps?: Object;
  isChecked?: boolean;
  isDisabled?: boolean;
  label: ReactNode;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  tabIndex?: number;
  checkmarkClassName?: string;
  labelClassName?: string;
};

const RadioButton = forwardRef<HTMLInputElement, Props>(function RadioButton(
  props: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    name,
    value,
    isChecked,
    isDisabled,
    className,
    onChange,
    inputProps,
    label,
    isCheckedClassName,
    tabIndex = -1,
    labelClassName,
    checkmarkClassName,
    ...rest
  } = props;

  return (
    <label
      className={cx(
        styles.container,
        className,
        props.isDisabled && styles.disabled,
        props.isChecked && isCheckedClassName,
      )}
      data-testid="radioButton-input"
      {...rest}
    >
      <input
        tabIndex={tabIndex}
        type="radio"
        name={name}
        value={value}
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChange}
        className={styles.radio}
        ref={ref}
        {...inputProps}
      />
      <span className={cx(styles.checkmark, checkmarkClassName)} />
      <span className={cx(styles.label, labelClassName)}>{label}</span>
    </label>
  );
});

RadioButton.defaultProps = {
  isDisabled: false,
  isChecked: false,
  className: '',
  inputProps: {},
};

export default RadioButton;
