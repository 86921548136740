/* istanbul ignore file */

const labels = {
  resetFiltered: '指定した条件を解除する',
  classOfService: 'クラス',
  //NOTE: Missing translation
  multipleProvider: '※複数宿泊施設あり',
  //NOTE: Missing translation
  maintenance: {
    title: 'メンテナンスのお知らせ',
    complimentary: '日頃より、楽天トラベルのサービスをご利用いただき、誠にありがとうございます。',
    introduction:
      '当楽天グループでは、より快適にサービスをご利用いただくため、サービス停止を伴うシステムメンテナンスを実施させていただきます。',
    tableTitle: '■ メンテナンスの詳細',
    tableContentTitle: '【内容】',
    tableContentNotice: 'システムメンテナンスにより、楽天トラベルの全サービスが利用不可になります',
    contentExtendedNotice:
      'サービスへの影響を最小限にするため、サービス再開時刻を繰り上げる場合もございます。',
    contentComplimentary:
      'お客様にはご迷惑をお掛けいたしますが、何卒ご理解とご協力を賜りますようお願い申し上げます。',
    faqTitle: '【お客様用お問い合わせ】',
    faqContent: 'ご利用に際しお困りのことがございましたらヘルプページをご確認ください。',
    signature: '楽天グループ株式会社',
  },
  jrReservationDetailNavigation: '詳細を見る (日本旅行サイトへ移動する)',
};

export default labels;
