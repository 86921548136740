import React, { KeyboardEvent, ReactNode, Ref } from 'react';

import LoadingCircle from './loadingCircle';

import cx from '../../utils/classnames';

import styles from './flatButton.module.scss';

export type Props = {
  /** Making component in loading state */
  isLoading?: boolean;

  /** Making component disabled */
  isDisabled?: boolean;

  /** Making component skeleton */
  isSkeleton?: boolean;

  /** Adjusting the min-height of button */
  isSmallerButtonByDefault?: boolean;

  /** Adding icon on the button's label */
  icon?: ReactNode;

  /** Setting the aria-label property for accessibility. */
  ariaLabel?: string;

  /** Custom style for wrapper */
  className?: string;

  /** Setting of image as the background of the component */
  backgroundUrl?: string;

  /** Button component behavioral type */
  type?: 'button' | 'submit' | 'reset';

  /** Type of component to display */
  classType?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'flat'
    | 'image'
    | 'list'
    | 'critical'
    | 'furusato'
    | 'rakutenMobile';

  /** Function to called upon button click */
  onClick?: (() => void) | React.MouseEventHandler<HTMLButtonElement>;

  /** Function to called upon key press */
  onKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void;

  /** Function called when button is focused */
  onFocus?: (event: React.FocusEvent) => void;

  /** Function called when button is entered */
  onMouseEnter?: () => void;

  /** Function called when button is left */
  onMouseLeave?: () => void;

  /** Displayed label of the component */
  children?: ReactNode;

  /** Custom style for label */
  labelClassName?: string;

  /** Native attr to define form element id which belongs to */
  form?: string;

  /** auto focus attribute */
  // eslint-disable-next-line travel/boolean-prop-prefix
  autoFocus?: boolean;

  /** inline style for button */
  style?: React.CSSProperties;

  /** ref of button */
  innerRef?: Ref<HTMLButtonElement>;
};

const classNameTypes = ['primary', 'list'];
const buttonSizes = ['image', 'list'];

export default function FlatButton(props: Props) {
  const {
    isLoading,
    isDisabled,
    isSkeleton,
    isSmallerButtonByDefault,
    icon,
    children,
    className,
    labelClassName,
    backgroundUrl,
    type = 'button',
    ariaLabel,
    classType = 'primary',
    innerRef,
    ...rest
  } = props;

  let bgImage: React.CSSProperties | undefined = undefined;
  if (classType === 'image' && backgroundUrl) {
    bgImage = {
      backgroundImage: `url(${backgroundUrl})`,
      backgroundRepeat: 'round',
    };
  }

  const classNameType = classNameTypes.includes(classType) ? styles.primary : styles[classType];
  const buttonType = isDisabled ? styles.disabled : isSkeleton ? styles.skeleton : classNameType;
  const buttonSize = buttonSizes.includes(classType)
    ? styles.buttonSizeImage
    : styles.buttonSizeDefault;
  const smallerButtonStyle = isSmallerButtonByDefault ? styles.smallerButton : '';
  const classNames = cx(
    styles.flexWrapper,
    styles.button,
    className,
    buttonType,
    buttonSize,
    smallerButtonStyle,
    isLoading && styles.loading,
  );

  return (
    <button
      aria-label={ariaLabel}
      style={bgImage}
      className={classNames}
      disabled={isSkeleton || isDisabled || isLoading}
      type={type}
      ref={innerRef}
      {...rest}
    >
      {isLoading ? (
        <LoadingCircle classType={classType} />
      ) : icon ? (
        <div className={cx(styles.iconDivDefault, styles.flexWrapper)}>
          <span key="icon" className={styles.iconFill}>
            {icon}
          </span>
          {children && (
            <div key="label" className={cx(labelClassName, styles.textWithIconWrapper)}>
              {children}
            </div>
          )}
        </div>
      ) : (
        <span key="label" className={labelClassName}>
          {children}
        </span>
      )}
    </button>
  );
}
