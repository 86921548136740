import { h, ComponentChildren } from 'preact';

import { L10nDate } from '@travel/i18n';
import { FULL_DATE_YOUBI_FORMAT_TRUNCATED_ZEROES } from '@travel/traveler-core/utils/dateTime';
import { cx } from '@travel/utils';

import styles from './journeyLegDate.module.scss';

type Props = {
  className?: string;
  wrapperClassName?: string;
  dateClassName?: string;
  date?: string;
  headerLabel?: JSX.Element;
  children: ComponentChildren;
  isInline?: boolean;
  /** To be used in case we dont show title but the content has to be aligned with other date column  */
  isReserveHeaderHeight?: boolean;
};

const JourneyLegDate = (props: Props) => {
  const {
    headerLabel,
    date,
    children,
    className,
    dateClassName,
    wrapperClassName,
    isReserveHeaderHeight,
    isInline = false,
  } = props;

  return (
    <div
      data-testid="journey-leg-date-component"
      className={cx(styles.container, wrapperClassName)}
    >
      {headerLabel || isReserveHeaderHeight ? (
        <p
          class={cx(styles.header, isReserveHeaderHeight && styles.reserveHeight)}
          data-testid="journey-leg-header"
        >
          {headerLabel || ''}
        </p>
      ) : null}
      <div class={cx(isInline && styles.inlineDateTime)}>
        {date && (
          <span class={cx(styles.date, dateClassName)} data-testid="journey-leg-date">
            <L10nDate value={`${date}`} format={FULL_DATE_YOUBI_FORMAT_TRUNCATED_ZEROES} />
          </span>
        )}
        <div class={className}>{children}</div>
      </div>
    </div>
  );
};

export default JourneyLegDate;
