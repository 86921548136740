import env from '@travel/env';

export const PAGE_TYPE_OBJ = {
  hotel_list: {
    pageType: 'hotel_list',
    siteSection: 'DatedList',
    pageName: 'trvrjrdp_search',
    rmpPageName: 'jr_search',
  },
  hotel_list_map: {
    pageType: 'hotel_list',
    siteSection: 'DatedList',
    pageName: 'trvrjrdp_search_map',
    rmpPageName: 'jr_search_map',
  },
  plan_list: {
    pageType: 'shop_item',
    siteSection: 'Product',
    pageName: 'trvrjrdp_plan_list',
    rmpPageName: 'jr_plan_list',
  },
  plan_detail: {
    pageType: 'shop_item',
    siteSection: 'Product',
    pageName: 'trvrjrdp_plan_detail',
    rmpPageName: 'jr_plan_detail',
  },
  train_list: {
    pageType: 'train_list',
    siteSection: 'TrainList',
    pageName: 'trvrjrdp_train_select',
    rmpPageName: 'jr_train_select',
  },
  itinerary: {
    pageType: 'itinerary',
    siteSection: 'Itinerary',
    pageName: 'trvrjrdp_itinerary',
    rmpPageName: 'jr_itinerary',
  },
  booking_list_current: {
    siteSection: 'Account',
    pageName: 'Account:Common:BookingList_Current',
    hasGenericRatOnly: true,
    hasDMP: false,
    hasTealium: false,
  },
  booking_list_past: {
    siteSection: 'Account',
    pageName: 'Account:Common:BookingList_Past',
    hasGenericRatOnly: true,
    hasDMP: false,
    hasTealium: false,
  },
  booking_list_canceled: {
    siteSection: 'Account',
    pageName: 'Account:Common:BookingList_Canceled',
    hasGenericRatOnly: true,
    hasDMP: false,
    hasTealium: false,
  },
};

export const RAT_JRDP_SERVICE_NAME = 'DpDom_B';

export const RAT_PARAMS_TO_PROPS = {
  generic: [
    {
      prop: 'couponCode',
      id: 'ratCouponId',
    },
  ],
  custom: [
    {
      prop: 'query',
      id: 'scond_train',
    },
    {
      prop: 'searchKey',
      id: 'sq_train',
    },
    {
      prop: 'providerIds',
      id: 'shopid_train',
    },
    {
      prop: 'providerName',
      id: 'provider_name',
    },
    {
      prop: 'providerCategories',
      id: 'provider_type_train',
    },
    {
      prop: 'itemIds',
      id: 'itemid_train',
    },
    {
      prop: 'departureDate',
      id: 'travel_start_date_train',
    },
    {
      prop: 'returnDate',
      id: 'travel_end_date_train',
    },
    {
      prop: 'departureStation',
      id: 'area_station_from_train',
    },
    {
      prop: 'destinationStation',
      id: 'area_station_to_train',
    },
    {
      prop: 'departureArea',
      id: 'travel_departure_area_train',
    },
    {
      prop: 'checkInDate',
      id: 'service_start_date_train',
    },
    {
      prop: 'checkOutDate',
      id: 'service_end_date_train',
    },
    {
      prop: 'destinationArea',
      id: 'accommodation_destination_train',
    },
    {
      prop: 'numberOfRooms',
      id: 'number_of_rooms_train',
    },
    {
      prop: 'numberOfAdults',
      id: 'number_of_adult_guests_train',
    },
    {
      prop: 'numberOfChildren',
      id: 'number_of_child_guests_train',
    },
    {
      prop: 'numberOfInfants',
      id: 'category_of_child_guests_train',
    },
    {
      prop: 'isBookable',
      id: 'is_bookable',
    },
    {
      prop: 'numberOfDays',
      id: 'lead_time_train',
    },
    {
      prop: 'stayNights',
      id: 'service_period_train',
    },
    {
      prop: 'numberOfMales',
      id: 'num_male_train',
    },
    {
      prop: 'numberOfFemales',
      id: 'num_female_train',
    },
    {
      prop: 'couponCode',
      id: 'coupon_used',
    },
    {
      prop: 'couponValue',
      id: 'coupon_value',
    },
    {
      prop: 'pointUsed',
      id: 'point_used',
    },
    {
      prop: 'pointRatio',
      id: 'point_grant_ratio',
    },
    {
      prop: 'resCreateDate',
      id: 'rescreatedate_train',
    },
    {
      prop: 'cartModifyHotelList',
      id: 'cart_modify_Hotellist',
    },
    {
      prop: 'cartModifyPlanList',
      id: 'cart_modify_Planlist',
    },
    {
      prop: 'cartModifyTrainList',
      id: 'cart_modify_Trainlist',
    },
  ],
  conversion: [
    {
      prop: 'purchaseOrder',
      id: 'purchase_order',
    },
    {
      prop: 'purchaseGms',
      id: 'purchase_gms',
    },
    {
      prop: 'purchaseItem',
      id: 'purchase_item',
    },
    {
      prop: 'reserveNum',
      id: 'reserve_num',
    },
  ],
};

export const DMP_PARAMS_TO_PROPS = {
  hotelid: 'providerIds',
  dtpcode: 'departureStation',
  arvcode: 'destinationStation',
  dtpdate: 'departureDate',
  arvdate: 'returnDate',
  gms: 'purchaseGmsDMP',
};

export const TEALIUM_PARAMS_TO_PROPS = {
  ratPageLayout: 'pageLayout',
  ratPageType: 'pageType',
  ratSiteSection: 'siteSection',
  ratPageName: 'pageName',
};

export const DMP_ARRAY_PARAMS = ['providerIds'];
export const DMP_SINGLE_VALUE_PARAMS = ['departureStation', 'destinationStation'];

export const RAT_ACCOUNT_ID = Number(env('UNIVERSAL_RAT_ACCOUNT_ID'));
export const RAT_SERVICE_ID = Number(env('UNIVERSAL_RAT_SERVICE_ID'));
export const RAT_STEP_PARAM_VALUES = `'step0':,'step1':,'step2':,'step2.5':'step3':,'step4':,'step4.5':`;
export const DMP_SERVICE = env('UNIVERSAL_DMP_SERVICE');
