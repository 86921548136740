import React, { ReactNode } from 'react';

import FlatButton from '../FlatButton';

import cx from '../../utils/classnames';

import styles from './dialogActions.module.scss';

export type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** Custom style for action buttons */
  actionButtonsClassName?: string;

  /** Custom style for primary button */
  buttonPrimaryClassName?: string;

  /** Label of the primary button */
  buttonLabelPrimary?: ReactNode;

  /** Label of the secondary button */
  buttonLabelSecondary?: ReactNode;

  /** Label of the tertiary button */
  buttonLabelTertiary?: ReactNode;

  /**  Any label above the action button */
  actionLabel?: ReactNode;

  /**  Class name for any label above the action button */
  actionLabelClassName?: string;

  /** if primary button is disabled */
  isDisabledPrimary?: boolean;

  /** if secondary button is disabled */
  isDisabledSecondary?: boolean;

  /** if tertiary button is disabled */
  isDisabledTertiary?: boolean;

  /** if primary button is loading */
  isLoadingPrimary?: boolean;

  /** onClick event of primary button */
  onClickPrimary?: () => void;

  /** onClick event of secondary button */
  onClickSecondary?: () => void;

  /* onClick event of tertiary button */
  onClickTertiary?: () => void;

  /** custom component for dialog actions */
  customActionsComponent?: ReactNode;
};

function DialogActions(props: Props) {
  const {
    className,
    actionButtonsClassName,
    buttonPrimaryClassName,
    buttonLabelPrimary,
    buttonLabelSecondary,
    buttonLabelTertiary,
    onClickPrimary,
    onClickSecondary,
    onClickTertiary,
    isDisabledPrimary,
    isDisabledSecondary,
    isDisabledTertiary,
    isLoadingPrimary,
    actionLabel,
    actionLabelClassName,
    customActionsComponent,
  } = props;

  if (
    !buttonLabelPrimary &&
    !buttonLabelTertiary &&
    !buttonLabelSecondary &&
    !customActionsComponent
  ) {
    return null;
  }

  const hasTertiaryButton = !!(buttonLabelTertiary && onClickTertiary);

  return (
    <div
      className={cx(className, styles.wrapper, hasTertiaryButton && styles.withTertiary)}
      data-testid="dialog-action-wrapper"
    >
      {actionLabel && <div className={actionLabelClassName}>{actionLabel}</div>}
      {hasTertiaryButton && (
        <FlatButton
          data-testid="button-tertiary"
          classType="tertiary"
          className={cx(styles.dialogButton, actionButtonsClassName)}
          onClick={onClickTertiary}
          isDisabled={isDisabledTertiary}
        >
          {buttonLabelTertiary}
        </FlatButton>
      )}
      {buttonLabelSecondary && onClickSecondary && (
        <FlatButton
          data-testid="button-secondary"
          classType="secondary"
          className={cx(styles.dialogButton, actionButtonsClassName)}
          onClick={onClickSecondary}
          isDisabled={isDisabledSecondary}
        >
          {buttonLabelSecondary}
        </FlatButton>
      )}
      {buttonLabelPrimary && onClickPrimary && (
        <FlatButton
          data-testid="button-primary"
          classType="primary"
          className={cx(styles.dialogButton, actionButtonsClassName, buttonPrimaryClassName)}
          onClick={onClickPrimary}
          isDisabled={isDisabledPrimary}
          isLoading={isLoadingPrimary}
        >
          {buttonLabelPrimary}
        </FlatButton>
      )}

      {customActionsComponent}
    </div>
  );
}

export default DialogActions;
