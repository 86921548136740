import React, { useEffect } from 'react';

import { useDeviceType, DESKTOP_SCREEN, TABLET_SCREEN } from '@travel/traveler-core/hooks';
import { PropMap } from '@travel/traveler-core/types/analytics';
import { isNotEmptyArray } from '@travel/utils';

import {
  PAGE_TYPE_OBJ,
  RAT_JRDP_SERVICE_NAME,
  RAT_PARAMS_TO_PROPS,
  RAT_STEP_PARAM_VALUES,
} from '../../constants/JRDPAnalytics';
import DMPTag from './DMPTag';
import Tealium from './Tealium';

const getRatParamsMap = (ratParamValues: Object, ratParamsToProps?: Object) => {
  let ratParamsMap = JSON.parse(
    JSON.stringify(ratParamsToProps ?? { generic: [], custom: [], conversion: [] }),
  );
  for (let [key, paramObj] of Object.entries(ratParamValues)) {
    for (let [id, value] of Object.entries(paramObj as Record<string, string>)) {
      ratParamsMap[key].unshift({
        prop: '',
        id,
        value,
      });
    }
  }

  return ratParamsMap;
};

/**
 * Generic and custom parameter PRD is updating in progress
 * Generic: https://confluence.rakuten-it.com/confluence/display/EVEREST/%28V1.1%29+Everest+website+RAT+generic+parameters
 * Custom: https://confluence.rakuten-it.com/confluence/display/EVEREST/%28V1.1%29+Everest+website+RAT+custom+parameters
 * Site Section: https://confluence.rakuten-it.com/confluence/display/EVEREST/Everest+RAT+page+names+and+site+sections
 */
const useRat = (updateData?: string) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      let event;
      if (typeof window.Event === 'function') {
        event = new Event('SINGLE_PAGE_APPLICATION_LOAD');
        document.dispatchEvent(event);
      } else {
        try {
          event = document.createEvent('Event');
          event.initEvent('SINGLE_PAGE_APPLICATION_LOAD', true, true);
          document.dispatchEvent(event);
        } catch (e) {
          console.error(e);
        }
      }
    }
    // I only want to dispatch when one of these props changes else we will get performance issues
  }, [updateData]);
};

export type Props = { [key: string]: string | number | undefined } & {
  page: string;
  stepParam?: string;
  updateData?: string /* pass the changed data if params need to be updated on data change */;
};

const JRDPDataLayer = (props: Props) => {
  const { page, stepParam, updateData } = props;
  const {
    pageType,
    siteSection,
    pageName,
    hasGenericRatOnly = false,
    hasDMP = true,
    hasTealium = true,
  } = PAGE_TYPE_OBJ[page];
  const deviceType = useDeviceType();
  const pageLayout = deviceType === TABLET_SCREEN ? 'tb' : deviceType;
  const ratPageName = `${pageName}_${pageLayout}`;
  const stepParams = ',' + RAT_STEP_PARAM_VALUES.replace(`'${stepParam}':`, `'${stepParam}':1`);

  const ratParamsMap = hasGenericRatOnly
    ? /** For MyPage there are only generic ratParams */
      getRatParamsMap({
        generic: {
          ratPageLayout: pageLayout,
          ratPageType: pageType,
          ratSiteSection: siteSection,
          ratPageName: pageName,
        },
        custom: {},
        conversion: {},
      } as any)
    : getRatParamsMap(
        {
          generic: {
            ratPageLayout: pageLayout,
            ratPageType: pageType,
            ratSiteSection: siteSection,
            ratPageName: ratPageName,
            ratGenre: RAT_JRDP_SERVICE_NAME,
          },
          custom: {},
          conversion: {
            complete: 1,
          },
        } as any,
        RAT_PARAMS_TO_PROPS,
      );

  // action
  useRat(updateData || pageName);

  const generateParamString = (paramObj: PropMap[]) => {
    return paramObj
      .map((param: PropMap) => {
        const paramValue = param.value ? param.value : props[param.prop];

        return props[param.prop] || param.value
          ? `'${param.id}':` + (typeof paramValue === 'string' ? `'${paramValue}'` : paramValue)
          : null;
      })
      .filter(params => params)
      .join(',');
  };

  return (
    <>
      {/* -- Generic Parameters -- */}
      {ratParamsMap.generic.map((genericParam: PropMap) =>
        props[genericParam.prop] || genericParam.value ? (
          <input
            key={genericParam.id}
            type="hidden"
            name="rat"
            id={genericParam.id}
            data-testid={`JRDPDataLayer-ratGenericParameter-${genericParam.id}`}
            value={genericParam.value ? genericParam.value : props[genericParam.prop]}
          />
        ) : null,
      )}

      {/*-- Custom Parameters -- */}
      {isNotEmptyArray(ratParamsMap.custom) && (
        <input
          type="hidden"
          name="rat"
          id="ratCustomParameters"
          data-testid="JRDPDataLayer-ratCustomParameters"
          value={`{${generateParamString(ratParamsMap.custom)}}`}
        />
      )}

      {/*-- Conversion Parameters -- */}
      {isNotEmptyArray(ratParamsMap.conversion) && (
        <input
          type="hidden"
          name="rat"
          id="ratCvEvent"
          data-testid="JRDPDataLayer-ratConversionParameters"
          value={`{${generateParamString(ratParamsMap.conversion)}${stepParams}`}
        />
      )}
      {/*-- DMP  params - configured via DMP_PARAMS_TO_PROPS -- */}
      {hasDMP && <DMPTag {...props} deviceType={deviceType === DESKTOP_SCREEN ? 'pc' : 'sp'} />}
      {/*-- Tealium Params - configured via TEALIUM_PARAMS_TO_PROPS -- */}
      {hasTealium && (
        <Tealium
          pageName={ratPageName}
          pageType={pageType}
          siteSection={siteSection}
          pageLayout={pageLayout}
        />
      )}
    </>
  );
};

export default React.memo(JRDPDataLayer);
