import { useState, useEffect, useContext } from 'react';
import UIComponentContext from '../contexts';

type DeviceType = 'sp' | 'tl' | 'pc';

/**
 * This custom hook use window.matchMedia and returns proper device type (sp, tl, pc) based on window width.
 * On SSR, it just simply use the value from DeviceTypeContext. Usually you can get the proper device type from useragent
 * and pass it down through context.
 */
function useDeviceType() {
  const { ssrDeviceType } = useContext(UIComponentContext);
  const [deviceType, setDeviceType] = useState((ssrDeviceType || 'sp') as DeviceType);

  useEffect(() => {
    if (!window.matchMedia) return;

    const mediaQueryTL = window.matchMedia('(max-width: 1024px)');
    const mediaQuerySP = window.matchMedia('(max-width: 743px)');

    function updateMediaQuery() {
      if (mediaQuerySP.matches) {
        setDeviceType('sp');
      } else if (mediaQueryTL.matches) {
        setDeviceType('tl');
      } else {
        setDeviceType('pc');
      }
    }

    updateMediaQuery();

    mediaQueryTL.addListener(updateMediaQuery);
    mediaQuerySP.addListener(updateMediaQuery);

    return () => {
      mediaQueryTL.removeListener(updateMediaQuery);
      mediaQuerySP.removeListener(updateMediaQuery);
    };
  }, []);

  return deviceType;
}

export default useDeviceType;
