import { h } from 'preact';

import EmptyFilteredListIcon from './EmptyFilteredListIcon';
import EmptyListIcon from './EmptyListIcon';

import { Translate } from '@travel/translation';
import { cx } from '@travel/utils';

import styles from './emptyState.module.scss';

type Props = { className?: string; name: string; isFiltered?: boolean };

function EmptyState(props: Props) {
  return (
    <div class={cx(props.className, styles.wrapper)} data-testid="emptyState-wrapper">
      {props.isFiltered ? (
        <>
          <EmptyFilteredListIcon name={props.name} />
          <p class={styles.emptyText}>
            {<Translate id="Booking_List.No_Reservations.With_Filter" />}
          </p>
        </>
      ) : (
        <>
          <EmptyListIcon name={props.name} />
          <p class={styles.emptyText}>
            <Translate id="Booking_List.No_Reservations.No_Filter" />
          </p>
        </>
      )}
    </div>
  );
}

export default EmptyState;
