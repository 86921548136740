import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { Translate } from '@travel/translation';
import { FlatButton } from '@travel/ui';
import UIDialog from '@travel/ui/components/Dialog';

import { closeFailSubmitCouponDialog } from 'store/furusatoNozei/actions';

import { getErrorMessage, getIsSubmitFailed } from 'store/furusatoNozei/selectors';

import styles from './furusatoErrorDialog.module.scss';

const FurusatoErrorDialog = () => {
  const dispatch = useDispatch();
  const isSubmitFailDialogOpen = useSelector(getIsSubmitFailed);
  const errorMessage = useSelector(getErrorMessage);
  const handleOnClick = useCallback(() => {
    dispatch(closeFailSubmitCouponDialog());
  }, [dispatch]);

  return isSubmitFailDialogOpen ? (
    <UIDialog
      isOpen={isSubmitFailDialogOpen}
      hasCustomAction
      isUseContentFlexBox
      className={styles.dialogWrapper}
      contentClassName={styles.containerDialog}
      onClose={handleOnClick}
    >
      <div class={styles.container}>
        {errorMessage.map((message, index) => (
          <span key={index}>{message}</span>
        ))}
        <div class={styles.buttonContainer}>
          <FlatButton onClick={handleOnClick}>
            <Translate id="Booking_List.Hometown_Tax.Coupon.Close" />
          </FlatButton>
        </div>
      </div>
    </UIDialog>
  ) : null;
};

export default FurusatoErrorDialog;
