import { useCallback, useEffect, useRef, useState } from 'react';

const DEBOUNCE_TIME = 400;

function useWindowSizeChange(debounce: number = DEBOUNCE_TIME) {
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | number>(0);

  const handleResize = useCallback(() => {
    clearTimeout(timerRef.current as number);
    timerRef.current = setTimeout(() => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    }, debounce);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timerRef.current as number);
    };
  }, [handleResize]);

  return { windowHeight, windowWidth };
}

export default useWindowSizeChange;
