import { h } from 'preact';

import { Bus, Car, Flight, Hotel, JRPackage, OverseasHotel, Package } from '@travel/icons/service';
import { Checkmark } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { IconTextTag } from '@travel/ui';
import { cx } from '@travel/utils';

import { ReservationStatusColor, ServiceType, Status } from 'constants/reservations';

import styles from './reservationTitle.module.scss';

const SERVICE_ICONS = {
  DOMESTIC: Hotel,
  DAY_USE: Hotel,
  ANA: Package,
  JAL: Package,
  JR: JRPackage,
  RENTAL_CAR: Car,
  BUS: Bus,
  OVERSEAS_HOTEL: OverseasHotel,
  OVERSEAS_AIR: Flight,
  OVERSEAS_DP: Package,
};

type Props = {
  serviceType: ServiceType;
  status: keyof typeof ReservationStatusColor;
  className?: string;
};

const services = {
  DOMESTIC: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service_Genre.Domestic.Hotel"
    />
  ),
  DAY_USE: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service_Genre.Domestic.Day-Use"
    />
  ),
  DOMESTIC_DP: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service_Genre.Domestic.Tour"
    />
  ),
  RENTAL_CAR: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service_Genre.Domestic.Rental_Cars"
    />
  ),
  BUS: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service_Genre.Domestic.BUS"
    />
  ),
  ANA: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service.Domestic.Tour_ANA"
    />
  ),
  JAL: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service.Domestic.Tour_JAL"
    />
  ),
  JR: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service.Domestic.Tour_JR"
    />
  ),
  OVERSEAS_DP: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service_Genre.Overseas.Tour"
    />
  ),
  OVERSEAS_AIR: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service_Genre.Overseas.Air"
    />
  ),
  OVERSEAS_HOTEL: (
    <Translate
      className={cx(styles.tagTitle, styles.typeTag)}
      id="MyPage_Enumeration.Travel_Service_Genre.Overseas.Hotel"
    />
  ),
};

const statusLabel = {
  [Status.UPCOMING]: <Translate id="MyPage_Enumeration.Reservation.Status.Reserved" />,
  [Status.CANCELED]: <Translate id="MyPage_Enumeration.Reservation.Status.Cancelled" />,
  [Status.PAST]: <Translate id="MyPage_Enumeration.Reservation.Status.Past" />,
  [Status.NOSHOW]: <Translate id="MyPage_Enumeration.Reservation.Remarks.No-Show" />,
  [Status.MODIFIED]: <Translate id="MyPage_Enumeration.Reservation.Remarks.Modified_Bakend" />,
  [Status.REQUESTED]: <Translate id="MyPage_Enumeration.Reservation.Status.Requested" />,
};

const ReservationTitle = (props: Props) => {
  const { serviceType, status, className } = props;
  const isUpComing = [Status.UPCOMING, Status.MODIFIED].some(
    checkedStatus => checkedStatus === status,
  );
  const ServiceIcon = SERVICE_ICONS[serviceType];

  return (
    <div class={cx(className, styles.tagContainer)}>
      <IconTextTag
        className={styles.rightPush}
        color="default"
        icon={<ServiceIcon size={16} className={styles.tagIcon} color="sonicSilver" />}
        label={<span class={cx(styles.tagTitle, styles.typeTag)}>{services[serviceType]}</span>}
      />
      <IconTextTag
        color={ReservationStatusColor[status]}
        label={<span class={styles.tagTitle}>{statusLabel[status]}</span>}
        icon={
          isUpComing ? <Checkmark size={12} color="cilantro" className={styles.tagIcon} /> : null
        }
      />
    </div>
  );
};

export default ReservationTitle;
