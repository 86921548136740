import React, { ChangeEvent, ReactNode, TouchEvent, useEffect, useReducer, useRef } from 'react';

import { cx } from '@travel/utils';

import TabButton from './TabButton';
import useWindowSizeChange from './useWindowSizeChange';

import { useDeviceType } from '../../hooks';
import reducer from './reducer';

import styles from './navigationTab.module.scss';

export type Tab = {
  id: string;
  title: ReactNode;
  content: ReactNode;
} | null;

type Props = {
  /** Additional class to control wrapper styles of this component from parent component */
  className?: string;
  /** Additional class to control tab menu styles */
  tabMenuClassName?: string;
  /** Additional class to control tab button styles */
  tabButtonClassName?: string;
  /** Additional class to control the direct button style */
  innerButtonClassName?: string;
  /** Additional class to control the selected button style */
  selectedInnerButtonClassName?: string;
  /** Additional class to control the panel list style */
  panelListClassName?: string;
  /** Should be array of object with keys id, title and content with values respectively */
  tabs: Array<Tab>;
  /** For controlling default selected tab onload */
  selectedTab: string;
  /** Callback function for parent component on tab selection */
  onClick?: (event: ChangeEvent) => void;
  /** Callback to be called when tab content has been changed */
  onChange?: (tabId: string) => void;
  /** For accessibility and screen reader support */
  ariaLabel: string;
  /** To control panel swipe behavior when user selects different tab */
  isSwipePanel: boolean;
  /** Flag to define whether we allow the component to render with width 0 or not */
  isAnimatedOnStart: boolean;
  /** Flag to define whether we allow the component to render with width 0 or not */
  numberOfSkeleton: number;
  /** Flag to define whether we allow the component to have touch events or not*/
  hasTouchEvents?: boolean;
  /** Optional content  to add to the tab list*/
  additionalTabContent?: ReactNode;
};

// Minimum swipe distance. Lower MIN_SWIPE_DISTANCE means higher sensitivity.
const MIN_SWIPE_DISTANCE = 50;

// Find the index of selected tab using tab id.
const getSelectedIndex = (tabs: Array<Tab>, selectedTab: string) => {
  const index = tabs.findIndex(tab => tab?.id === selectedTab);
  return index === -1 ? 0 : index;
};

function NavigationTab(props: Props) {
  const {
    className,
    tabButtonClassName,
    innerButtonClassName,
    selectedInnerButtonClassName,
    panelListClassName,
    tabs,
    ariaLabel,
    selectedTab,
    onClick,
    onChange,
    isSwipePanel,
    tabMenuClassName,
    isAnimatedOnStart,
    numberOfSkeleton,
    hasTouchEvents = true,
    additionalTabContent,
    ...rest
  } = props;

  const selectedTabIndex = getSelectedIndex(tabs, selectedTab);

  const isInitialized = useRef(true);
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const deviceType = useDeviceType();
  const isTouchDevice = deviceType === 'sp';
  const defaultTab = tabs.some(tab => tab?.id === selectedTab)
    ? selectedTab
    : tabs[0] && tabs[0].id;

  const initialState = {
    selected: defaultTab,
    selectedPanel: `${defaultTab}-panel`,
    left: 0,
    touchStartX: 0,
    beingTouched: false,
    originalOffset: 0,
    containerWidth: 0,
    direction: '',
    changedOffset: 0,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { windowHeight, windowWidth } = useWindowSizeChange();

  /** for initialization of containerWidth and left */
  useEffect(() => {
    if (isInitialized.current && contentContainerRef.current) {
      isInitialized.current = false;
      const nodeWidth = contentContainerRef.current?.getBoundingClientRect().width || 0;
      dispatch({ type: 'SET_CONTAINER_WIDTH', key: 'containerWidth', value: nodeWidth });
    }
  }, [selectedTabIndex]);

  /**
   * (SP size screen only)
   * this update is necessary for orientation change or size change
   */
  useEffect(() => {
    if (!isInitialized.current && contentContainerRef.current && isTouchDevice && isSwipePanel) {
      const nodeWidth = contentContainerRef.current?.getBoundingClientRect().width || 0;
      dispatch({ type: 'SET_CONTAINER_WIDTH', key: 'containerWidth', value: nodeWidth });
    }
  }, [windowHeight, windowWidth, state.selected, tabs, isTouchDevice, isSwipePanel]);

  useEffect(() => {
    dispatch({
      type: 'SET_SELECTED_PANEL',
      key: 'selectedPanel',
      value: `${state.selected}-panel`,
    });
  }, [state.selected]);

  useEffect(() => {
    dispatch({ type: 'SET_SELECTED_TAB', key: 'selected', value: defaultTab });
  }, [defaultTab]);

  // TODO try to avoid any
  const onClickTab = (event: any) => {
    const selectedTabId = event.currentTarget.id;
    dispatch({ type: 'SET_SELECTED_TAB', key: 'selected', value: selectedTabId });
    onClick?.(event);
  };

  const onKeyPressed = (newTabId?: string) => {
    if (newTabId && !isTouchDevice) {
      dispatch({ type: 'SET_SELECTED_TAB', key: 'selected', value: newTabId });
      onChange?.(newTabId);
      tabRefs.current[newTabId]?.focus();
    }
  };

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    const touchX = e.targetTouches[0].clientX;
    dispatch({ type: 'SET_ORIGINAL_OFFSET', key: 'originalOffset', value: state.left });
    dispatch({ type: 'SET_TOUCH_START_X', key: 'touchStartX', value: touchX });
    dispatch({ type: 'SET_BEING_TOUCHED', key: 'beingTouched', value: true });
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (state.beingTouched) {
      const touchX = e.targetTouches[0].clientX;

      const difference = touchX - state.touchStartX;
      const swipeDirection = difference < 0 ? 'left' : 'right';
      dispatch({ type: 'SET_DIRECTION', key: 'direction', value: swipeDirection });
      dispatch({ type: 'SET_CHANGED_OFFSET', key: 'changedOffset', value: Math.abs(difference) });
    }
  };

  const handleTouchEnd = () => {
    dispatch({ type: 'SET_TOUCH_START_X', key: 'touchStartX', value: 0 });
    dispatch({ type: 'SET_BEING_TOUCHED', key: 'beingTouched', value: false });

    let newSelection;
    const currIndex = getSelectedIndex(tabs, state.selected);
    /* Find the transition distance based on container width */
    if (state.direction === 'left' && state.changedOffset > MIN_SWIPE_DISTANCE) {
      newSelection = tabs[currIndex + 1]?.id || state.selected;
    } else if (state.direction === 'right' && state.changedOffset > MIN_SWIPE_DISTANCE) {
      newSelection = tabs[currIndex - 1]?.id || state.selected;
    } else {
      newSelection = state.selectedTab;
    }

    if (newSelection) {
      dispatch({ type: 'SET_SELECTED_TAB', key: 'selected', value: newSelection });
      dispatch({ type: 'SET_CHANGED_OFFSET', key: 'changedOffset', value: 0 });
      onChange?.(newSelection);
    }
  };

  const tabList = [] as ReactNode[];
  const panelList = [] as ReactNode[];
  const touchEvents = hasTouchEvents && {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
  };

  const tabRefs = useRef<Record<string, HTMLButtonElement | null>>({});

  tabs.forEach(tab => {
    if (tab !== null) {
      const panelId = `${tab.id}-panel`;
      const isSelected = state.selected === tab.id;

      /* Common for both PC and SP */
      tabList.push(
        <TabButton
          key={tab.id}
          tab={tab}
          tabs={tabs}
          selectedTabIndex={getSelectedIndex(tabs, tab.id)}
          tabButtonClassName={tabButtonClassName}
          innerButtonClassName={innerButtonClassName}
          selectedInnerButtonClassName={selectedInnerButtonClassName}
          isSelected={isSelected}
          onClickTab={onClickTab}
          onKeyPressed={onKeyPressed}
          ref={ref => (tabRefs.current[tab.id] = ref)}
        />,
      );
      /* For PC */
      panelList.push(
        <div
          tabIndex={0}
          className={cx(
            styles.tabpanel,
            state.selectedPanel === panelId && styles.showPanel,
            panelListClassName,
          )}
          role="tabpanel"
          id={panelId}
          aria-labelledby={tab.id}
          key={panelId}
          {...touchEvents}
        >
          {tab.content}
        </div>,
      );
    }
  });

  return (
    <div className={className} {...rest}>
      <div
        role="tablist"
        data-testid="navigationTab-tablist-wrapper"
        aria-label={ariaLabel}
        className={tabMenuClassName}
      >
        {tabList}
        {additionalTabContent && additionalTabContent}
      </div>
      {panelList}
    </div>
  );
}

NavigationTab.defaultProps = {
  tabs: [],
  selectedTab: '',
  ariaLabel: '',
  isSwipePanel: true,
  isAnimatedOnStart: true,
  numberOfSkeleton: 3,
};

export default NavigationTab;
