import { h } from 'preact';

import DialogUI, { Props as DialogUIProps } from '@travel/traveler-core/components/Dialog';
import { cx } from '@travel/utils';

import styles from './dialog.module.scss';

type Props = { isFullWidth?: boolean } & DialogUIProps;

const Dialog = (props: Props) => {
  const { className, contentClassName, isFullWidth = false, ...rest } = props;
  return (
    <DialogUI
      className={cx(className, styles.dialog)}
      contentClassName={cx(isFullWidth && styles.fullWidth, contentClassName, styles.content)}
      isUseContentFlexBox={false}
      isClosableByClickingOutside={true}
      {...rest}
    />
  );
};

export default Dialog;
