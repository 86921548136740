import { h, VNode } from 'preact';
import { createPortal } from 'preact/compat';

import JRDPDataLayer from '@travel/traveler-core/components/JRDPDataLayer';

export type Props = { [key: string]: string | number | undefined } & {
  page: string;
  updateData?: string /* pass the changed data if params need to be updated on data change */;
};

const PreactPortal = ({ children }: { children: VNode }) => {
  return typeof window !== 'undefined' ? createPortal(children, document.body) : null;
};

const DataLayer = (props: Props) => {
  return (
    <PreactPortal>
      <JRDPDataLayer {...props} />
    </PreactPortal>
  );
};

export default DataLayer;
