import React, { useEffect, useRef, useState } from 'react';

import {
  DMP_ARRAY_PARAMS,
  DMP_PARAMS_TO_PROPS,
  DMP_SERVICE,
  DMP_SINGLE_VALUE_PARAMS,
  PAGE_TYPE_OBJ,
} from '../../constants/JRDPAnalytics';

export type Props = { [key: string]: string | number | undefined } & {
  page: string;
  deviceType: string;
};

function usePrevious(value: string) {
  const ref = useRef('');
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const DMPTag = (props: Props) => {
  const { page, deviceType } = props;
  const { rmpPageName } = PAGE_TYPE_OBJ[page];

  const [dmpParams, setDmpParams] = useState('');
  const prevDmpParams = usePrevious(dmpParams);

  const mappedParams = Object.keys(DMP_PARAMS_TO_PROPS).reduce((result, param) => {
    const propName = DMP_PARAMS_TO_PROPS[param];
    if (props[propName]) {
      if (DMP_ARRAY_PARAMS.includes(propName)) {
        result[param] = `${props[propName]}`.split(',');
      } else if (DMP_SINGLE_VALUE_PARAMS.includes(propName)) {
        result[param] = `${props[propName]}`.split(',')[0];
      } else {
        result[param] = props[propName];
      }
    }
    return result;
  }, {});

  useEffect(() => {
    if (document?.getElementById('grp15_ias')) {
      document?.getElementById('grp15_ias')?.remove();
    }
  }, [dmpParams]);

  useEffect(() => {
    const grp15_prm = {
      service: DMP_SERVICE,
      page: rmpPageName,
      d: deviceType,
      ...mappedParams,
    };

    const scriptCode = `
      var grp15_prm = ${JSON.stringify(grp15_prm)};
    `;

    setDmpParams(scriptCode);

    const loadDMPScript = () => {
      document.getElementById('dmp-script')?.remove();
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.id = 'dmp-script';
      script.setAttribute('data-testid', 'DMPTag-script');
      script.src = '//ias.r10s.jp/grp15/trvrdp.js';
      document.body.appendChild(script);
    };

    const loadDMPParams = () => {
      document.getElementById('dmp-param')?.remove();
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'dmp-param';
      script.setAttribute('data-testid', 'DMPTag-params');
      script.textContent = scriptCode;
      document.body.appendChild(script);
    };

    if (document && dmpParams && dmpParams !== prevDmpParams) {
      setTimeout(loadDMPParams, 1);
      setTimeout(loadDMPScript, 1);
    }
  }, [mappedParams, deviceType, rmpPageName, prevDmpParams, dmpParams]);

  return <></>;
};

export default React.memo(DMPTag);
