import React, { ReactNode } from 'react';

import { cx } from '@travel/utils';

import styles from './iconTextTag.module.scss';

type Props = {
  color?: 'default' | 'green' | 'yellow' | 'red';
  haveBorder?: boolean;
  label?: ReactNode;
  icon?: ReactNode;
  className?: string;
  isEmpty?: boolean;
};

const IconTextTag = (props: Props) => {
  const {
    icon,
    label,
    color = 'default',
    className,
    haveBorder = false,
    isEmpty = false,
    ...rest
  } = props;

  const customClassName = cx(
    styles.container,
    styles[color],
    haveBorder && styles.border,
    isEmpty && styles.empty,
    className,
  );

  return (
    <div className={customClassName} {...rest}>
      {icon ? icon : null}
      {label ? label : null}
    </div>
  );
};

export default IconTextTag;
