import { h } from 'preact';

import Skeleton from '../Skeleton';

import LoadingCircle from '@travel/ui/components/FlatButton/loadingCircle';

import styles from './skeletonList.module.scss';

const SkeletonList = (props: { className?: string }) => {
  return (
    <div class={props.className} data-testid="skeletonList-wrapper">
      <Skeleton />
      <Skeleton />
      <div class={styles.loading}>
        <LoadingCircle classType="primary" isLoading />
      </div>
    </div>
  );
};

export default SkeletonList;
