import { h, ComponentChild } from 'preact';

import { Close } from '@travel/icons/ui';

import styles from './dialogHeader.module.scss';

type Props = {
  title: ComponentChild;
  icon?: ComponentChild;
  onClickIcon?: () => void;
};

const DialogHeader = (props: Props) => {
  const { icon, onClickIcon } = props;
  return (
    <div className={styles.wrapper}>
      {onClickIcon && (
        <button
          className={styles.icon}
          onClick={onClickIcon}
          type="button"
          data-testid="dialogHeader-close-button"
        >
          {icon || <Close size={16} />}
        </button>
      )}
      {props.title}
    </div>
  );
};

export default DialogHeader;
