import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { CouponSearchResponseBody } from 'RakutenMobile-Types';
import { RakutenMobileState } from './reducer';

export const getRakutenMobile = (state: RootState) => state.rakutenMobile;

export const getIsCouponDialogOpen = createSelector(
  getRakutenMobile,
  (state: RakutenMobileState) => state.isCouponSelectionDialogOpen,
);

const getCouponListState = createSelector(
  getRakutenMobile,
  (state: RakutenMobileState) => state.couponList,
);

export const getCouponList = createSelector(
  getCouponListState,
  (couponList: CouponSearchResponseBody) => couponList.selectionCoupons,
);

export const getCurrentPrice = createSelector(
  getCouponListState,
  (couponList: CouponSearchResponseBody) => couponList.currentBookingPrice,
);

export const getRemovedCoupon = createSelector(
  getCouponListState,
  (couponList: CouponSearchResponseBody) => couponList.removingCoupon,
);

export const getIsSubmitFailed = createSelector(
  getRakutenMobile,
  (state: RakutenMobileState) => state.isSubmitCouponFailedDialogOpen,
);

export const getIsFetchingCouponList = createSelector(
  getRakutenMobile,
  (state: RakutenMobileState) => state.isFetchingCouponList,
);

export const getIsSubmittingCoupon = createSelector(
  getRakutenMobile,
  (state: RakutenMobileState) => state.isSubmittingCoupon,
);

export const getErrorMessage = createSelector(
  getRakutenMobile,
  (state: RakutenMobileState) => state.mobileErrorMessage,
);
