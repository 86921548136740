import React, { useCallback, useState, ReactElement, ReactNode } from 'react';

import { IconProps } from '@travel/icons';
import { COLORS, IconColor } from '@travel/icons/withIconProps';

import cx from '../../utils/classnames';

import styles from './iconTextLink.module.scss';

export type iconPositionType = 'left' | 'right';

type Props = {
  /** Display Icon */
  icon?: ReactElement<IconProps> | false | null;

  /** Position of display icon */
  iconPosition?: iconPositionType;

  /** Text next to the icon */
  text: ReactNode;

  /** Custom style for wrapper */
  className?: string;

  /** disable/enable */
  isDisabled?: boolean;

  /** Callback function on click */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

  /** Color when cursor is hovered */
  hoveredColor?: IconColor;

  /** Color when cursor is not hovered */
  unhoveredColor?: IconColor;
};

export default function IconTextLink(props: Props) {
  const {
    icon,
    iconPosition = 'left',
    text,
    className,
    isDisabled,
    onClick,
    hoveredColor = 'lincolnGreen',
    unhoveredColor = 'cilantro',
    ...rest
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const onMouseOver = useCallback(() => setIsHovered(true), []);
  const onMouseOut = useCallback(() => setIsHovered(false), []);

  const content = (
    <>
      <span className={styles.iconWrap} data-testid="iconTextLink-icon">
        {icon &&
          React.cloneElement(icon, isHovered ? { color: hoveredColor } : { color: unhoveredColor })}
      </span>
      <span
        className={styles.textWrap}
        style={{ color: isHovered ? COLORS[hoveredColor] : COLORS[unhoveredColor] }}
      >
        {text}
      </span>
    </>
  );

  return onClick ? (
    <button
      className={cx(styles.iconTextLink, styles[iconPosition], className)}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      type="button"
      disabled={isDisabled}
      data-testid={'iconTextLink-button'}
      {...rest}
    >
      {content}
    </button>
  ) : (
    <div
      className={cx(styles.iconTextLink, styles[iconPosition], className)}
      data-testid={'iconTextLink-button'}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      {...rest}
    >
      {content}
    </div>
  );
}
