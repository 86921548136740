import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  ReactNode,
  TouchEvent,
} from 'react';
import usePortal from 'react-useportal';

import { ArrowLeft, Close } from '@travel/icons/ui';
import { Animation } from '@travel/ui';

import useDeviceType from '../../utils/useDeviceType';
import DialogActions from '../DialogActions';
import DialogContent from '../DialogContent';

import cx from '../../utils/classnames';

import styles from './dialog.module.scss';

const TABBABLE_ELEMENTS =
  'a:not([disabled]), input:not([disabled]), button:not([disabled]), textarea:not([disabled]), select:not([disabled]), details:not([disabled]), [tabindex]:not([tabindex="-1"])';

export type Props = {
  /** Custom style for wrapper */
  className?: string;
  wrapperClassName?: string;
  contentClassName?: string;
  contentParentClassName?: string;
  headerClassName?: string;
  actionClassName?: string;
  actionButtonsClassName?: string;
  buttonPrimaryClassName?: string;
  overlayClassName?: string;
  /** Display contents of the dialog */
  children?: ReactNode;
  /** Boolean to set visibility of the dialog */
  isOpen?: boolean;
  /** Title of the modal */
  title?: ReactNode;
  /** Label of the primary button */
  buttonLabelPrimary?: ReactNode;
  /** Label of the secondary button */
  buttonLabelSecondary?: ReactNode;
  /** Label of the tertiary button */
  buttonLabelTertiary?: ReactNode;
  /**  Any label above the action button */
  actionLabel?: ReactNode;
  /**  Class name for any label above the action button */
  actionLabelClassName?: string;
  /** if primary button is disabled */
  isDisabledPrimary?: boolean;
  /** if secondary button is disabled */
  isDisabledSecondary?: boolean;
  /** if tertiary button is disabled */
  isDisabledTertiary?: boolean;
  /** if primary button is loading */
  isLoadingPrimary?: boolean;
  /** onClick event of primary button */
  onClickPrimary?: () => void;
  /** onClick event of secondary button */
  onClickSecondary?: () => void;
  /* onClick event of tertiary button */
  onClickTertiary?: () => void;
  /** function to call on dialog close */
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  /** boolean to set transition state of the dialog  */
  hasEnableBack?: boolean;
  /** function to call on dialog close */
  onBackClick?: () => void;
  /** When it's true, the dialog will always be rendered with `opacity: 0` and `z-index: -1` when isOpen is false.
   *  When it's false, the dialog will not be rendered when isOpen is false.
   *  This prop is useful to set focus on an element in Dialog while opening Dialog on iOS device, because iOS doesn't
   *  allow to set focus after the click event (like setTimeout), and if we don't render the Dialog when isOpen is false,
   *  we cannot set focus during the click event because it's not rendered at that time.
   *  It should be **false** if you don't understand what it is doing.
   */
  isToggledByOpacity?: boolean;
  /** For Dialog's content,
   * it's better to apply with 'display: block' by default (to prevent any effects from flexbox)
   * To reduce an additional children wrapper/override the content wrapper style,
   * we can simply use 'contentClassName' prop.
   */
  isUseContentFlexBox?: boolean;
  /** There is some rare case which is, the element has been displayed inside another dialog
   * and placed behind the parent's dialog. If it's true, the z-index will be increased by 1 from default
   * The value should be **false** if you have not faced any issue
   */
  isDisplayedOnDialog?: boolean;
  /** Flag to define whether the body need to be freezed when the dialog is open or not.
   * NOTE: To fix a case when some absolute items inside the dialog but cannot be reached
   */
  isNotPreventBodyScrollingOnOpen?: boolean;
  /** Flag to define whether the dialog can be closed by clicking outside of the dialog. */
  isClosableByClickingOutside?: boolean;
  /** Flag to define whether the dialog uses custom action buttons from the child */
  hasCustomAction?: boolean;
  /** Flag to define whether the autofocus should be disabled or not */
  isDisableAutoFocus?: boolean;
  /** Should close modal on esc key press */
  shouldCloseOnEscPress?: boolean;
  /** Disable the default scroll behavior to let the parent component control it */
  isScrollDisable?: boolean;
  /** Flag to define whether the event bubbling could pass tru the Dialog wrapper or not
   * NOTE: in preact case and (3rd party) component's touch event inside dialog has been bind to document, it will not be working properly
   */
  isWrapperTouchEventStopPropagated?: boolean;
  contentRef?: React.RefObject<{
    contains: (node: Node) => boolean;
  }>;
  /** custom component for dialog actions */
  customActionsComponent?: ReactNode;
  /** Flag to enable animation when component mounted */
  hasAnimation?: boolean;
};

export function ModalHeader({
  hasEnableBack,
  headerClassName,
  onBackClick,
  onClose,
  title,
}: {
  hasEnableBack?: boolean;
  headerClassName?: string;
  onBackClick?: () => void;
  onClose?: (event: React.MouseEvent) => void;
  title: ReactNode;
}) {
  return (
    <div className={cx(styles.header, headerClassName)}>
      {hasEnableBack ? (
        <button
          data-testid="back-button"
          type="button"
          className={styles.iconButton}
          onClick={onBackClick}
          aria-label="back button"
        >
          <ArrowLeft size={24} />
        </button>
      ) : (
        <button
          data-testid="close-button"
          type="button"
          className={styles.iconButton}
          onClick={onClose}
          aria-label="close button"
        >
          <Close size={24} />
        </button>
      )}
      <h3 id="dialog_title" className={styles.title} data-testid="dialog-title">
        {title}
      </h3>
    </div>
  );
}

function Dialog(props: Props) {
  const { Portal } = usePortal();

  const {
    isToggledByOpacity,
    className,
    wrapperClassName,
    contentClassName,
    contentParentClassName,
    headerClassName,
    actionClassName,
    overlayClassName,
    onClose,
    hasEnableBack,
    onBackClick,
    isDisplayedOnDialog,
    isDisableAutoFocus = false,
    children,
    isNotPreventBodyScrollingOnOpen,
    isOpen = null,
    title = null,
    isUseContentFlexBox = true,
    isClosableByClickingOutside = false,
    hasCustomAction = false,
    isScrollDisable = false,
    shouldCloseOnEscPress = true,
    isWrapperTouchEventStopPropagated = true,
    contentRef: outerRef,
    hasAnimation,
    ...rest
  } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(isOpen);
  let currentPageOffset = useRef<number | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useDeviceType() === 'sp';
  const previousFocus = useRef<HTMLElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const storeScrollPosition = useCallback(() => {
    if (!isNotPreventBodyScrollingOnOpen) {
      // prevent html, body scrolling while dialog is opened
      document.body.style.overflow = 'hidden';

      // outer scroll on iOS will be prevented,
      // but the browser navbar always be showed
      currentPageOffset.current = window.pageYOffset;
      if (!animationFadeIn) {
        document.body.style.position = 'fixed';
      }
      if (wrapperRef.current && animationFadeIn) {
        // keep current Y when dialog opened
        wrapperRef.current.style.top = `${window.scrollY}px`;
      }
      document.body.style.width = '100%';
      // Workaround for iOS 15.0 Render Bug with position: fixed dialog
      // https://stackoverflow.com/questions/69589924/ios-15-minimized-address-bar-issue-with-fixed-position-full-screen-content
      if (isMobile) document.documentElement.style.height = '100vh';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [isNotPreventBodyScrollingOnOpen, isMobile]);

  const restoreScrollPosition = useCallback(() => {
    if (!isDisplayedOnDialog) {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('width');
      if (isMobile) document.documentElement.style.removeProperty('height');
    }

    if (currentPageOffset.current !== null) {
      window.scrollTo(0, currentPageOffset.current);
    }
  }, [isDisplayedOnDialog, isMobile]);

  useEffect(() => {
    // close the dialog a bit later when isToggledByOpacity is true, so that the scroll restoration could
    // finish before we close the dialog, in order to avoid blinking
    if (isToggledByOpacity && !isOpen) {
      setTimeout(() => {
        setIsDialogOpen(isOpen);
      }, 100);
    } else {
      setIsDialogOpen(isOpen);
    }
  }, [isOpen, isToggledByOpacity]);

  // props.isOpen: false -----( run effect: storeScrollPosition )--------> props.isOpen: true -------( clear effect: restoreScrollPosition )-------> props.isOpen: false
  useEffect(() => {
    if (isOpen) storeScrollPosition();

    return () => {
      if (isOpen && !isScrollDisable) restoreScrollPosition();
    };
  }, [
    isOpen,
    isNotPreventBodyScrollingOnOpen,
    restoreScrollPosition,
    storeScrollPosition,
    isScrollDisable,
  ]);

  useEffect(() => {
    if (isOpen && !isDisableAutoFocus && document.activeElement instanceof HTMLElement) {
      previousFocus.current = document.activeElement;
    } else {
      previousFocus.current = null;
    }
    if (contentRef.current && !isDisableAutoFocus) {
      const [firstFocusableElement] = getFirstAndLastFocusableElement();
      firstFocusableElement?.focus();
    }
    return () => {
      previousFocus.current?.focus();
    };
  }, [isDisableAutoFocus, isOpen]);

  const isModalDialog = title !== null;
  const hasNoActions =
    !props.buttonLabelPrimary &&
    !props.buttonLabelSecondary &&
    !props.buttonLabelTertiary &&
    !props.customActionsComponent;

  // for PC dialog with title
  const animationFadeIn = hasAnimation && !isModalDialog;
  // for SP dialog without title
  const animationSlideUp = hasAnimation && isModalDialog;

  useImperativeHandle(outerRef, () => {
    return {
      contains: (node: Node) => contentRef.current?.contains(node) || false,
    };
  });

  const handleClose = (e: React.MouseEvent | React.KeyboardEvent<HTMLDivElement>) => {
    if (props.isOpen === null) {
      setIsDialogOpen(false);
    } else {
      onClose?.(e);
    }
  };

  // this is to stop from any touch event from going to the parent element
  const handleTouchEvent = (e: TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const getFirstAndLastFocusableElement = () => {
    const focusableElements = contentRef.current?.querySelectorAll<HTMLElement>(TABBABLE_ELEMENTS);
    let firstFocusableElement: HTMLElement | null = null;
    let lastFocusableElement: HTMLElement | null = null;
    if (focusableElements) {
      firstFocusableElement = focusableElements[0];
      lastFocusableElement = focusableElements[focusableElements?.length - 1];
    }
    return [firstFocusableElement, lastFocusableElement];
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (shouldCloseOnEscPress && e.key === 'Escape') {
      // We sometimes have dialogs in dialogs. We want to close only the top dialog on Escape
      e.stopPropagation();
      handleClose(e);
    }
    /**
     * Focus trap is incomplete. Currently it's possible for focus to escape the dialog under certain edge cases
     * (e.g. focus is on previous swipe button, use moves to first image, previous button disappear, user shift-tabs to outside the dialog)
     */
    if (e.key === 'Tab') {
      const [firstFocusableElement, lastFocusableElement] = getFirstAndLastFocusableElement();
      if (e.shiftKey && document.activeElement === firstFocusableElement) {
        e.preventDefault();
        lastFocusableElement?.focus();
      } else if (!e.shiftKey && document.activeElement === lastFocusableElement) {
        e.preventDefault();
        firstFocusableElement?.focus();
      }
    }
  };

  const wrapperTouchEventProps = isWrapperTouchEventStopPropagated
    ? {
        onTouchStart: handleTouchEvent,
        onTouchMove: handleTouchEvent,
        onTouchEnd: handleTouchEvent,
      }
    : null;

  // Keep returns near bottom otherwise they might interfere with rendering hooks
  // https://stackoverflow.com/questions/55622768/uncaught-invariant-violation-rendered-more-hooks-than-during-the-previous-rende
  if (!isToggledByOpacity && !isOpen) {
    return null;
  }

  const dialog = (
    <div
      role="dialog"
      data-testid="dialog-wrapper"
      aria-labelledby="dialog_title"
      aria-modal="true"
      className={cx(
        styles.wrapper,
        wrapperClassName,
        isDisplayedOnDialog && styles.overlappedDialog,
        isToggledByOpacity && styles.toggledOpacity,
        isDialogOpen ? styles.open : styles.close,
      )}
      {...wrapperTouchEventProps}
      onKeyDown={handleKeyDown}
      ref={wrapperRef}
    >
      <div
        role="button"
        tabIndex={-1}
        className={cx(
          styles.overlay,
          overlayClassName,
          isClosableByClickingOutside ? styles.isClosable : '',
        )}
        onClick={handleClose}
        aria-hidden={true}
      />
      <div
        ref={contentRef}
        className={cx(
          styles.dialog,
          className,
          isModalDialog && styles.modalDialog,
          animationSlideUp && styles.slideUp,
        )}
      >
        {isModalDialog && (
          <ModalHeader
            hasEnableBack={hasEnableBack}
            headerClassName={headerClassName}
            onBackClick={onBackClick}
            onClose={handleClose}
            title={title}
          />
        )}
        <DialogContent
          className={cx(
            contentClassName,
            styles.content,
            hasNoActions && !hasCustomAction && styles.noAction,
            contentParentClassName,
            isUseContentFlexBox && styles.withFlexBox,
          )}
          data-testid="dialog-content"
        >
          {children}
        </DialogContent>
        {!hasNoActions && (
          <DialogActions className={cx(styles.actions, actionClassName)} {...rest} />
        )}
      </div>
    </div>
  );

  return (
    /**
     * className prop is exposed to inner dialog as the position of the wrapper is not supposed to change in any case.
     * The override className will be applied to the dialog container
     */
    <Portal>
      {animationFadeIn ? (
        <Animation animationDuration="short" isAppear={true}>
          {dialog}
        </Animation>
      ) : (
        dialog
      )}
    </Portal>
  );
}

export default Dialog;
