import { h, ComponentChildren } from 'preact';
import { useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import EmptyState from '../EmptyState';
import ReservationCard from '../ReservationCard';
import SearchForm from '../SearchForm';
import SkeletonList from '../SkeletonList';

import { Translate } from '@travel/translation';
import { FlatButton } from '@travel/ui';
import { cx, isNotEmptyArray } from '@travel/utils';

import { fetchMoreReservation } from 'store/reservationsList/actions';

import { Period, ServiceType, Status } from 'constants/reservations';

import {
  getIsFetching,
  getIsFetchingMore,
  getItems,
  getNextFetching,
  hasMoreReservations,
} from 'store/reservationsList/selectors';

import styles from './reservationList.module.scss';

type Props = {
  className?: string;
  selectedTab: Status;
  queryPeriod?: Period;
  querySelectedService?: ServiceType[];
};

function Container(props: Props & { children: ComponentChildren; searchClassName?: string }) {
  const {
    queryPeriod,
    querySelectedService,
    selectedTab,
    className,
    children,
    searchClassName,
  } = props;

  return (
    <div class={cx(className, styles.wrapper)} data-testid={`reservationList-${selectedTab}-tab`}>
      <SearchForm
        selectedTab={selectedTab}
        queryPeriod={queryPeriod}
        querySelectedService={querySelectedService}
        className={searchClassName}
      />
      {children}
    </div>
  );
}

function ReservationList(props: Props) {
  const { queryPeriod, querySelectedService, selectedTab } = props;
  const dispatch = useDispatch();
  const reservationsList = useSelector(getItems);
  const nextFetchingElement = useSelector(getNextFetching);
  const isLoading = useSelector(getIsFetching);
  const isLoadingMore = useSelector(getIsFetchingMore);
  const isPaginationDisplayed = useSelector(hasMoreReservations);
  const handlePagination = useCallback(() => {
    dispatch(
      fetchMoreReservation({
        period: queryPeriod,
        serviceType: querySelectedService,
        reservationList: selectedTab,
      }),
    );
  }, [queryPeriod, querySelectedService, dispatch, selectedTab]);
  if (isLoading) {
    return (
      <Container {...props}>
        <SkeletonList />
      </Container>
    );
  }

  if (!isLoading && !isNotEmptyArray(reservationsList?.reservations)) {
    const shouldIgnorePeriodCondition =
      selectedTab === Status.UPCOMING || queryPeriod === Period.PAST_6_MONTH;

    return (
      <Container {...props} searchClassName={styles.emptyResultSearchContainer}>
        <EmptyState
          name={selectedTab}
          isFiltered={Boolean(
            (!shouldIgnorePeriodCondition && !!queryPeriod) ||
              isNotEmptyArray(querySelectedService),
          )}
        />
      </Container>
    );
  }

  return (
    <Container {...props}>
      <div class={styles.listWrapper}>
        {reservationsList.reservations.map(reservation => {
          return <ReservationCard key={reservation.reservationId} reservation={reservation} />;
        })}
        {isPaginationDisplayed && (
          <FlatButton
            data-testid="reservationList-pagination-button"
            classType="secondary"
            className={styles.nextReservations}
            isLoading={isLoadingMore}
            onClick={handlePagination}
          >
            <Translate
              id="Booking_List.Footer.List_Indicator.Pagination"
              data={{ number_of_bookings: nextFetchingElement }}
            />
          </FlatButton>
        )}
      </div>
    </Container>
  );
}

export default ReservationList;
