import React, { useEffect } from 'react';

import env from '@travel/env';

import { TEALIUM_PARAMS_TO_PROPS } from '../../constants/JRDPAnalytics';

export type Props = { [key: string]: string | number | undefined } & {
  pageName: string;
  pageType: string;
  siteSection: string;
  pageLayout: string;
};

const Tealium = (props: Props) => {
  const mappedParams = Object.keys(TEALIUM_PARAMS_TO_PROPS).reduce((result, param) => {
    const propName = TEALIUM_PARAMS_TO_PROPS[param];
    result[param] = props[propName];
    return result;
  }, {});

  useEffect(() => {
    const tealiumTagURL = env('UNIVERSAL_TEALIUM_TAG_URL');
    if (window.utag) {
      // removing old data
      Object.keys(window.utag_data).forEach(key => {
        window.utag_data[key] = undefined;
      });
      // writing new data
      Object.keys(mappedParams).forEach(key => {
        window.utag_data[key] = mappedParams[key];
      });
      window.utag.view(mappedParams);
    } else {
      window.utag_data = { ...mappedParams };
      if (tealiumTagURL) {
        new Promise((resolve, reject) => {
          try {
            const tealiumUtagScript = document.createElement('script');
            tealiumUtagScript.setAttribute('src', tealiumTagURL);
            tealiumUtagScript.setAttribute('type', 'text/javascript');
            tealiumUtagScript.setAttribute('defer', 'true');
            tealiumUtagScript.setAttribute('data-testid', 'TealiumTag-script');

            tealiumUtagScript.addEventListener('load', () => {
              resolve({ status: true });
            });

            tealiumUtagScript.addEventListener('error', () => {
              reject({
                status: false,
                message: `Failed to load the tealium script`,
              });
            });

            document.head.appendChild(tealiumUtagScript);
          } catch (err) {
            reject(err);
          }
        })
          .then(() => {
            window.utag.view(mappedParams);
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  }, [mappedParams]);

  return <></>;
};

export default React.memo(Tealium);
