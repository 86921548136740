import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { Furusato } from '@travel/icons/service';
import { Checkmark } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { FlatButton } from '@travel/ui';

import { fetchCouponList } from 'store/furusatoNozei/actions';

import { CouponStatus } from 'constants/reservations';

import styles from './furusatoNosei.module.scss';

type Props = {
  hometownTaxCouponStatus: CouponStatus;
  reservationId: string;
};

const FurusatoNoseiButton = (props: Props) => {
  const { hometownTaxCouponStatus, reservationId } = props;
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(fetchCouponList({ reservationId }));
  }, [dispatch, reservationId]);
  const isDisabled = hometownTaxCouponStatus === CouponStatus.DISABLE;
  return (
    <>
      {hometownTaxCouponStatus === CouponStatus.APPLICABLE || isDisabled ? (
        <>
          <FlatButton
            classType="furusato"
            className={styles.button}
            onClick={handleClick}
            isDisabled={isDisabled}
          >
            <Furusato
              size={20}
              className={styles.icon}
              color={isDisabled ? 'pastelGray' : 'white'}
            />
            <Translate id="Booking_List.Reservation_Summary.Hometown_Tax.Use_Coupon" />
          </FlatButton>
          <p class={styles.notice}>
            <Translate id="Booking_List.Reservation_Summary.Hometown_Tax.Explanation" />
          </p>
        </>
      ) : (
        <>
          <p class={styles.applied}>
            <Checkmark size={16} className={styles.icon} />
            <Translate id="Booking_List.Reservation_Summary.Hometown_Tax.Message.Coupon_Applied" />
          </p>
        </>
      )}
    </>
  );
};
export default FurusatoNoseiButton;
