import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import ListElement from './ListElement';

import { getCurrency, getCurrencySign, L10nMoney, L10nNumber } from '@travel/i18n';
import { Translate } from '@travel/translation';
import { Skeleton } from '@travel/ui';
import isNotEmptyArray from '@travel/ui/utils/arrays';

import { Dialog, DialogHeader } from 'components/CustomDialog';

import { closeCouponSelection, submitCoupon } from 'store/furusatoNozei/actions';

import { FurusatoCoupon } from 'FurusatoNozei-Types';

import {
  getCouponList,
  getCurrentPrice,
  getIsCouponDialogOpen,
  getIsFetchingCouponList,
  getIsSubmittingCoupon,
  getRemovedCoupon,
} from 'store/furusatoNozei/selectors';

import styles from './furusatoCouponSearchDialog.module.scss';

const FurusatoCouponSearchDialog = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsCouponDialogOpen);
  const isFetchingCouponList = useSelector(getIsFetchingCouponList);
  const isSubmittingCoupon = useSelector(getIsSubmittingCoupon);
  const couponList = useSelector(getCouponList);
  const currentPrice = useSelector(getCurrentPrice);
  const removedCoupon = useSelector(getRemovedCoupon);

  const currency = useSelector(getCurrency);
  const currencySymbol = getCurrencySign(currency);

  const [selectedCoupon, setSelectedCoupon] = useState(couponList[0]);

  useEffect(() => {
    setSelectedCoupon(couponList[0]);
  }, [couponList]);

  const handleCloseDialog = useCallback(() => {
    dispatch(closeCouponSelection());
  }, [dispatch]);

  const handleCouponSelection = useCallback(
    (coupon: FurusatoCoupon) => {
      setSelectedCoupon(coupon);
    },
    [setSelectedCoupon],
  );

  const handleCouponSubmit = useCallback(() => {
    dispatch(
      submitCoupon({
        couponCode: selectedCoupon.couponCode,
        discountedPrice: selectedCoupon.discountedPrice,
      }),
    );
  }, [selectedCoupon, dispatch]);

  return (
    <Dialog
      isOpen={isOpen}
      contentClassName={styles.dialogContent}
      className={styles.dialogContainer}
      buttonLabelPrimary={<Translate id="Booking_List.Hometown_Tax.Coupon.Apply" />}
      actionButtonsClassName={styles.button}
      actionLabelClassName={styles.actionLabelContainer}
      actionLabel={
        <div class={styles.priceContainer}>
          <div class={styles.currentPriceContainer}>
            <Translate id="Booking_List.Hometown_Tax.Coupon.Total_Amount.Before_Coupon_Apply" />
            <span class={styles.lineThrough}>
              <L10nMoney value={currentPrice} isJPYAlternative={false} />
              (<Translate id="Booking_List.Hometown_Tax.Coupon.Tax_Included" />)
            </span>
          </div>
          <div class={styles.discountedPriceContainer}>
            <Translate id="Booking_List.Hometown_Tax.Coupon.Total_Amount" />
            <span class={styles.bold}>
              <span class={styles.actionPrice}>
                <L10nNumber value={selectedCoupon && selectedCoupon.discountedPrice} />
              </span>
              {currencySymbol}
              (<Translate id="Booking_List.Hometown_Tax.Coupon.Tax_Included" />)
            </span>
          </div>
        </div>
      }
      onClickPrimary={handleCouponSubmit}
      onClose={handleCloseDialog}
      actionClassName={styles.actionDialog}
      isFullWidth={true}
      isLoadingPrimary={isSubmittingCoupon}
    >
      <DialogHeader
        title={<Translate id="Booking_List.Hometown_Tax.Popup_Title" />}
        onClickIcon={handleCloseDialog}
      />
      {!isFetchingCouponList ? (
        <ul>
          <ListElement
            type="warning"
            warningLabel={<Translate id="Booking_List.Hometown_Tax.Notice.Apply" />}
          />
          {removedCoupon && <ListElement type="warning" removedCoupon={removedCoupon} />}
          {isNotEmptyArray(couponList) &&
            couponList.map((coupon, index) => (
              <ListElement
                type={
                  selectedCoupon && coupon.couponCode === selectedCoupon.couponCode
                    ? 'selected'
                    : 'default'
                }
                coupon={coupon}
                key={index}
                onClick={handleCouponSelection}
                removedCoupon={removedCoupon}
                currency={currency}
              />
            ))}
        </ul>
      ) : (
        <div class={styles.loadingWrapper} data-testid="furusatoCouponSearchDialog-loading-wrapper">
          <Skeleton className={styles.loadingItem} height="90px" width="unset" />
          <Skeleton className={styles.loadingItem} height="90px" width="unset" />
          <Skeleton className={styles.loadingItem} height="90px" width="unset" />
        </div>
      )}
    </Dialog>
  );
};

export default FurusatoCouponSearchDialog;
