import React, { ReactNode } from 'react';

import styles from './invisible.module.scss';

type Props = {
  children?: ReactNode;
};

/**
 * Hide the children element for SEO purpose with display:none;
 */
function Invisible(props: Props) {
  const { children, ...rest } = props;

  return (
    <div className={styles.hidden} {...rest}>
      {children}
    </div>
  );
}

export default Invisible;
