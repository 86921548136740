import { h } from 'preact';

import { IconTextTag } from '@travel/ui';
import { cx } from '@travel/utils';

import styles from './skeleton.module.scss';

const Skeleton = () => {
  return (
    <article class={styles.container}>
      <div class={styles.mediaContainer}>
        <div class={cx(styles.skeleton, styles.reservationMedia)} />
      </div>
      <div class={styles.tagContainer}>
        <IconTextTag haveBorder isEmpty color="default" className={styles.tagSpacing} />
        <IconTextTag haveBorder isEmpty color="default" />
      </div>
      <div class={styles.actionContainer}>
        <div class={styles.action} />
        <div class={styles.action} />
        <div class={styles.action} />
      </div>
    </article>
  );
};

export default Skeleton;
