import React, { useEffect, useRef, useState } from 'react';
import { VideoJsPlayer } from 'video.js';

import ReactPlayerLoader from '@brightcove/react-player-loader';

import { Photo } from '@travel/icons/ui';
import { isNotEmptyArray } from '@travel/utils';

import cx from '../../utils/classnames';

import styles from './media.module.scss';
import './media.scss';

export type Source = {
  /** String of MIME type */
  mimeType?: string;
  /** String of media query */
  mediaQuery?: string;
  /** String of possible images represented by the source for the browser */
  srcset?: string;
  /** String of list of source sizes that describes the final rendered width of the image represented by the source */
  sizes?: string;
};

export type Props = {
  /** Custom style for media */
  className?: string;

  /** Test id for the component */
  'data-testid'?: string;

  /** Custom style for wrapper */
  wrapperClassName?: string;

  /** Media type */
  type?: string;

  /** String of media id */
  id?: string;

  /** Flag if text has been translated by Google */
  isTextsGoogleTranslated?: boolean;

  /**
   * String of media url. The url can be for image or video.
   * if both video id and url are set, we will use the url instead
   * */
  url?: string;

  /** Video id */
  videoId?: string;

  /** String of video id */
  videoVendorId?: string;

  /** String of video player id */
  videoPlayerId?: string;

  /** String of video player id */
  videoAccountId?: string;

  /** String of thumbnail video url */
  thumbnailUrl?: string;

  /** String of media alt */
  alt?: string;

  /** Array of image source options */
  imageSources?: Array<Source>;

  /** Flag video is playable or not */
  isPlayable?: boolean;

  /** String to define loading attr on Image */
  loading?: 'eager' | 'lazy';

  /** React node to define custom placeholder  */
  placeholder?: React.ReactNode;

  /** Component ref  */
  innerRef?: React.Ref<HTMLDivElement>;

  /** Callback to be called when the component is clicked  */
  onClick?: (event: React.MouseEvent) => void;

  tabIndex?: number;

  /** Callback to be called when the component is clicked  */
  onKeyDown?: (event: React.KeyboardEvent) => void;

  /** Callback to be called image has failed to load  */
  onImageError?: () => void;

  /** Experimental img attribute https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/fetchPriority */
  fetchPriority?: 'high' | 'low' | 'auto';

  /** Should not return a div only the media component */
  isMediaOnly?: boolean;

  /** Should Pause Video or not */
  shouldPauseVideo?: boolean;
} & Omit<Source, 'mimeType' | 'mediaQuery'>;

function MediaPlaceholder(props: {
  className?: string;
  placeholder?: React.ReactNode;
  testId?: string;
}) {
  return (
    <div className={cx(props.className, styles.empty)} data-testid={props.testId}>
      {props.placeholder || <Photo className={styles.icon} size={24} />}
    </div>
  );
}

const DEFAULT_VIDEO_PLAYER_ID = 'OPM5er7M2';
const DEFAULT_VIDEO_ACCOUNT_ID = '5068808271001';
const DEFAULT_VIDEO_ARRTS_PROPS = {
  className: 'brightcover-react-player',
};

function Video(props: Props) {
  const [isError, setIsError] = useState(false);
  const videoJsPlayer = useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    if (videoJsPlayer.current && props.shouldPauseVideo) {
      videoJsPlayer.current.pause();
    }
  }, [props.shouldPauseVideo]);

  return props.videoVendorId && !isError ? (
    <div className={cx(props.className, !props.isPlayable && styles.disabled)}>
      <ReactPlayerLoader
        data-testid={props['data-testid']}
        attrs={DEFAULT_VIDEO_ARRTS_PROPS}
        accountId={props.videoAccountId || DEFAULT_VIDEO_ACCOUNT_ID}
        playerId={props.videoPlayerId || DEFAULT_VIDEO_PLAYER_ID}
        videoId={props.videoVendorId}
        onFailure={() => setIsError(true)}
        onSuccess={(success: any) => {
          const player = success.ref;
          videoJsPlayer.current = player;
          player.on('ended', () => {
            player.currentTime(0);
          });
        }}
      />
    </div>
  ) : (
    <MediaPlaceholder
      className={props.className}
      placeholder={props.placeholder}
      testId={props['data-testid']}
    />
  );
}

function Image(props: Props) {
  const img = (
    <img
      data-testid={props['data-testid']}
      className={cx(props.className, styles.media)}
      src={props.url}
      alt={props.alt}
      srcSet={props.srcset}
      sizes={props.sizes}
      loading={props.loading}
      onError={props.onImageError}
      {...(props.fetchPriority ? { fetchpriority: props.fetchPriority } : {})}
    />
  );

  if (!isNotEmptyArray(props.imageSources)) return img;

  return (
    <picture>
      {props.imageSources.map(source => (
        <source
          data-testid={props['data-testid']}
          srcSet={source.srcset}
          sizes={source.sizes}
          media={source.mediaQuery}
          type={source.mimeType}
        />
      ))}
      {img}
    </picture>
  );
}

function Media(props: Props) {
  const {
    id,
    className,
    wrapperClassName,
    url,
    thumbnailUrl,
    alt,
    type,
    isPlayable,
    fetchPriority,
    'data-testid': dataTestId,
    isTextsGoogleTranslated,
    onClick,
    onKeyDown,
    onImageError,
    placeholder,
    loading,
    innerRef,
    shouldPauseVideo,
    srcset,
    sizes,
    // video
    videoId,
    videoVendorId,
    videoAccountId,
    videoPlayerId,
    isMediaOnly,
    ...rest
  } = props;

  let component = (
    <MediaPlaceholder testId={dataTestId} className={className} placeholder={placeholder} />
  );

  if (url && type?.toLowerCase() === 'image') {
    // image tye
    component = <Image {...props} {...(isMediaOnly ? { ref: innerRef } : {})} />;
  } else if (url || videoVendorId) {
    // case: only video id exist
    if (!url) {
      if (isPlayable) component = <Video {...props} />;
      else
        component = (
          <Image {...props} url={props.thumbnailUrl} {...(isMediaOnly ? { ref: innerRef } : {})} />
        );
    } else {
      // case: only url / both video, url exist
      component = <video className={cx(className, styles.media)} src={url} controls={isPlayable} />;
    }
  }
  if (isMediaOnly) {
    return component;
  }

  return (
    <div
      ref={innerRef}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={wrapperClassName}
      id={id}
      {...rest}
    >
      {component}
    </div>
  );
}

Media.defaultProps = {
  className: '',
  type: 'image',
  url: '',
  alt: '',
  isPlayable: false,
  onClick: () => {},
  id: '',
};

export const MediaWithRef = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => (
  <Media {...props} innerRef={ref} />
));

export default Media;
