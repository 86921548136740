import { h } from 'preact';

import styles from './emptyState.module.scss';

type Props = { name: string };

function EmptyListIcon(props: Props) {
  const pathId = `paint0_radial_117_22013-${props.name}`;

  return (
    <svg
      data-testid="emptyState-emptyList-icon"
      class={styles.icon}
      width="320"
      height="160"
      viewBox="0 0 320 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M320 0H0V160H320V0Z" />
      <path d="M320 90.8H0.560059V151.84H320V90.8Z" fill={`url(#${pathId})`} />
      <path
        d="M206.2 71.36H113.56V47.36C113.56 46 114.6 44.96 115.96 44.96H203.72C205.08 44.96 206.12 46 206.12 47.36V71.36H206.2Z"
        fill="#B3CF9B"
      />
      <path
        d="M205.08 133.2H114.36C113 133.2 111.96 132.16 111.96 130.8V127.92H207.48V130.8C207.48 132.16 206.44 133.2 205.08 133.2Z"
        fill="#B3B3B3"
      />
      <path d="M212.76 107.76H107L113.56 71.36H206.2L212.76 107.76Z" fill="white" />
      <path
        d="M153.48 78H125.24C123.88 78 122.84 76.96 122.84 75.6V60.56C122.84 59.2 123.88 58.16 125.24 58.16H153.48C154.84 58.16 155.88 59.2 155.88 60.56V75.6C155.88 76.88 154.84 78 153.48 78Z"
        fill="#EDF2E9"
      />
      <path
        d="M195.8 78H167.56C166.2 78 165.16 76.96 165.16 75.6V60.56C165.16 59.2 166.2 58.16 167.56 58.16H195.8C197.16 58.16 198.2 59.2 198.2 60.56V75.6C198.2 76.88 197.16 78 195.8 78Z"
        fill="#EDF2E9"
      />
      <path
        d="M206.84 68.16H112.92C111.8 68.16 110.76 68.96 110.52 70.16L108.36 82.08C108.36 82.24 108.36 82.4 108.36 82.48V92.64C108.36 94 109.4 95.04 110.76 95.04H209C210.36 95.04 211.4 94 211.4 92.64V82.48C211.4 82.32 211.4 82.16 211.4 82.08L209.24 70.16C209 69.04 207.96 68.16 206.84 68.16Z"
        fill="white"
      />
      <path
        d="M208.52 84.24C208.28 83.12 207.32 82.24 206.12 82.24H113.64C112.52 82.24 111.48 83.04 111.24 84.24L107 107.76V119.28H212.76V107.76L208.52 84.24Z"
        fill="#DCEAD2"
      />
      <path
        d="M41.5203 33.4401L39.8403 35.1201"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.4002 40.48L32.7202 42.16"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.52 42.24L40 40.72"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.1601 34.88L32.6401 33.36"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M245.92 23.92L244.24 25.6"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M238.8 30.96L237.12 32.64"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M245.92 32.72L244.4 31.2"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M238.56 25.36L237.04 23.84"
        stroke="#DCEAD2"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M72.0001 79.36C73.5907 79.36 74.8801 78.0706 74.8801 76.48C74.8801 74.8894 73.5907 73.6 72.0001 73.6C70.4095 73.6 69.1201 74.8894 69.1201 76.48C69.1201 78.0706 70.4095 79.36 72.0001 79.36Z"
        stroke="#DCEAD2"
        stroke-width="3"
      />
      <path
        d="M283.6 64.88C285.191 64.88 286.48 63.5906 286.48 62C286.48 60.4094 285.191 59.12 283.6 59.12C282.01 59.12 280.72 60.4094 280.72 62C280.72 63.5906 282.01 64.88 283.6 64.88Z"
        stroke="#DCEAD2"
        stroke-width="3"
      />
      <path
        d="M210.36 127.92H109.4C108.04 127.92 107 126.88 107 125.52V110.16C107 108.8 108.04 107.76 109.4 107.76H210.36C211.72 107.76 212.76 108.8 212.76 110.16V125.52C212.76 126.8 211.72 127.92 210.36 127.92Z"
        fill="#B3CF9B"
      />
      <defs>
        <radialGradient
          id={pathId}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(160.595 103.447) rotate(90) scale(42.4076 168.905)"
        >
          <stop offset="0.1927" stop-color="#E6E6E6" />
          <stop offset="1" stop-color="#E6E6E6" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default EmptyListIcon;
