import React, { HTMLAttributes } from 'react';

import { Close } from '@travel/icons/ui';
import UIDialog, { Props as DialogProps } from '@travel/ui/components/Dialog';
import { cx } from '@travel/utils';

import Invisible from '../Invisible';

import styles from './dialog.module.scss';

export type Props = {
  children: React.ReactNode;
  /** Flag to define whether the Close's dialog button will be hidden or not */
  isHideCloseButtonDialog?: boolean;
  /**
   * Flag to define whether the auto-padding bottom will be applied or not
   * There will be a special case which we are custom dialog action inside dialog content.
   * */
  isResetContentBottomSpacing?: boolean;
  /**
   * Flag to define whether the dialog content will be index when dialog is not open
   * it will hide the content with <Invisible />
   */
  isSEOContentIndexedOnHidden?: boolean;
  /**
   * Specific props for close button
   */
  closeButtonProps?: HTMLAttributes<HTMLButtonElement>;
  /** Flag to enable animation when component mounted */
  hasAnimation?: boolean;
  dialogWrapperClassName?: string;
} & Partial<DialogProps>;

function Dialog(props: Props) {
  const isModalStyle = props.title !== null;
  const {
    isOpen,
    contentClassName,
    className,
    children,
    closeButtonProps = {},
    isHideCloseButtonDialog,
    isResetContentBottomSpacing,
    isSEOContentIndexedOnHidden,
    hasAnimation,
    dialogWrapperClassName,
    ...restProps
  } = props;
  return isOpen ? (
    <UIDialog
      {...restProps}
      isOpen={isOpen}
      isClosableByClickingOutside={true}
      className={cx(className, isModalStyle ? styles.wrapperModal : styles.wrapperDialog)}
      wrapperClassName={cx(styles.wrapper, dialogWrapperClassName)}
      contentClassName={cx(
        styles.container,
        isModalStyle ? styles.containerModal : styles.containerDialog,
        isResetContentBottomSpacing && styles.resetPaddingBottom,
      )}
      hasAnimation={hasAnimation}
    >
      <div className={cx(styles.contentWrapper, contentClassName)}>{children}</div>
      {!isModalStyle && !isHideCloseButtonDialog && (
        <button
          {...closeButtonProps}
          type="button"
          aria-label="close button"
          className={styles.closeButton}
          onClick={props.onClose}
          data-testid="dialog-close-button"
        >
          <Close />
        </button>
      )}
    </UIDialog>
  ) : isSEOContentIndexedOnHidden ? (
    <Invisible data-testid="dialog-invisible">{children}</Invisible>
  ) : null;
}

export default Dialog;
