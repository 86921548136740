import { h, JSX } from 'preact';

import { getL10nDate } from '@travel/i18n';
import { Translate } from '@travel/translation';
import { FULL_DATE_YOUBI_FORMAT_TRUNCATED_ZEROES } from '@travel/traveler-core/utils/dateTime';
import { Alert } from '@travel/ui';

import { Notifications } from 'ReservationsList-Types';

import { LANGUAGE_CODE, MARKET_CODE } from 'constants/common';

import styles from './notification.module.scss';

type Props = {
  notifications?: Notifications;
  paymentDeadlineDate?: string;
};

const notificationsLabels = (key: string, date?: string) =>
  ({
    cancelable: <Translate id="Booking_List.Reservation_Summary.Notices.Hotel_Modify_Offline" />,
    cancelDeadlineOver: (
      <Translate id="Booking_List.Reservation_Summary.Notices.Hotel_Cancel_Deadline" />
    ),
    bookingModifiable: (
      <Translate id="Booking_List.Reservation_Summary.Notices.Bus_Modify_Request" />
    ),
    qrTicket: <Translate id="Booking_List.Reservation_Summary.Notices.Bus_QR_Available" />,
    paymentRequired: (
      <Translate
        id="Booking_List.Reservation_Summary.Notices.Payment_Required"
        data={{ deadline_of_payment: date }}
      />
    ),
    allianceBooking: <Translate id="Booking_List.Reservation_Summary.Notices.Alliance" />,
    jrdp: <Translate id="Booking_List.Reservation_Summary.Notices.Alliance_NTA" />,
    refund: <Translate id="Booking_List.Reservation_Summary.Notices.Refund" />,
  }[key]);

const Notification = (props: Props) => {
  const { notifications, paymentDeadlineDate } = props;
  if (!notifications) return null;

  return (
    <>
      {Object.keys(notifications).reduce((alerts: Array<JSX.Element>, notification) => {
        if (notifications[notification]) {
          alerts.push(
            <Alert
              type="info"
              isClosable={false}
              iconClassName={styles.icon}
              title={notificationsLabels(
                notification,
                paymentDeadlineDate
                  ? getL10nDate(
                      LANGUAGE_CODE,
                      paymentDeadlineDate,
                      FULL_DATE_YOUBI_FORMAT_TRUNCATED_ZEROES,
                      true,
                      MARKET_CODE,
                    )
                  : '',
              )}
              className={styles.container}
              variation="balloon"
            />,
          );
        }
        return alerts;
      }, [])}
    </>
  );
};

export default Notification;
