import dayjs from 'dayjs';
import { h } from 'preact';
import { useMemo, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { getCustomDateTimeFormat } from '@travel/i18n/src/utils/l10n';
import { Checkmark, DropdownArrow } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { useTranslation } from '@travel/traveler-core/hooks';
import { FlatButton, StyledSelectBox } from '@travel/ui';
import { OptionItem } from '@travel/ui/components/StyledSelectBox';
import { cx, isNotEmptyArray } from '@travel/utils';

import { getIsFetching } from 'store/reservationsList/selectors';
import { optionItem, optionItemIcon, popupWrapper, selectedOptionItem } from './searchForm.styles';

import { pushLocation } from 'store/__router/actions';

import paths from 'constants/paths';
import { Period, ServiceType, Status } from 'constants/reservations';

import styles from './searchForm.module.scss';

type Props = {
  className?: string;
  selectedTab: Status;
  queryPeriod?: Period;
  querySelectedService?: ServiceType[];
};

type PeriodOption = {
  text: string;
  value: Period;
  yearDiff: number;
};

export const SERVICES = [
  {
    text: <Translate id="MyPage_Enumeration.Travel_Service_Genre.Domestic.Hotel" />,
    value: ServiceType.DOMESTIC,
  },
  {
    text: <Translate id="MyPage_Enumeration.Travel_Service_Genre.Domestic.Day-Use" />,
    value: ServiceType.DAY_USE,
  },
  {
    text: <Translate id="MyPage_Enumeration.Travel_Service_Genre.Domestic.Tour" />,
    value: ServiceType.DOMESTIC_DP,
  },
  {
    text: <Translate id="MyPage_Enumeration.Travel_Service_Genre.Domestic.Rental_Cars" />,
    value: ServiceType.RENTAL_CAR,
  },
  {
    text: <Translate id="MyPage_Enumeration.Travel_Service_Genre.Domestic.BUS" />,
    value: ServiceType.BUS,
  },
  {
    text: <Translate id="MyPage_Enumeration.Travel_Service_Genre.Overseas.Hotel" />,
    value: ServiceType.OVERSEAS_HOTEL,
  },
  {
    text: <Translate id="MyPage_Enumeration.Travel_Service_Genre.Overseas.Air" />,
    value: ServiceType.OVERSEAS_AIR,
  },
  {
    text: <Translate id="MyPage_Enumeration.Travel_Service_Genre.Overseas.Tour" />,
    value: ServiceType.OVERSEAS_DP,
  },
];

const FIXED_PERIOD: PeriodOption = {
  text: '',
  value: Period.PAST_6_MONTH,
  yearDiff: 0,
};
const usePeriods = () => {
  const fixedPeriodText = useTranslation({
    id: 'Booking_List.Header.Dates_Filters.Recent_Bookings',
  });

  return useMemo(() => {
    const fixedPeriod = { ...FIXED_PERIOD, text: fixedPeriodText };

    const maxYear = dayjs();
    const format = getCustomDateTimeFormat('ja-JP', 'JPN').Y;
    const PERIODS: Array<PeriodOption> = [
      fixedPeriod,
      {
        text: '',
        value: Period.THIS_YEAR,
        yearDiff: 0,
      },
      {
        text: '',
        value: Period.LAST_YEAR,
        yearDiff: 1,
      },
      {
        text: '',
        value: Period['2_YEARS_AGO'],
        yearDiff: 2,
      },
      {
        text: '',
        value: Period['3_YEARS_AGO'],
        yearDiff: 3,
      },
    ];
    return PERIODS.reduce((result: Array<OptionItem>, period) => {
      const year = maxYear.subtract(period.yearDiff || 0, 'year');
      result.push({
        ...period,
        text: period.text || dayjs(year).format(format),
        icon: <Checkmark size={16} className={optionItemIcon} />,
      });
      return result;
    }, []);
  }, [fixedPeriodText]);
};

function SearchForm(props: Props) {
  const { selectedTab, queryPeriod, querySelectedService } = props;
  const periods = usePeriods();
  const isFetching = useSelector(getIsFetching);
  const [selectedService] = useState<Array<ServiceType>>(querySelectedService || []);
  const [selectedPeriod, setSelectedPeriod] = useState(queryPeriod || FIXED_PERIOD.value);
  const [onPeriodOptionDisplayed, setOnPeriodOptionDisplayed] = useState(false);
  const dispatch = useDispatch();

  const onClickSearch = option => {
    const period = option.value;
    setSelectedPeriod(period as Period);
    handlePushLocation({ period: period, serviceType: selectedService });
  };
  const handlePushLocation = ({ serviceType, period }) => {
    const params = {
      serviceType,
      reservationList: selectedTab,
      ...(props.selectedTab !== Status.UPCOMING && { period }),
    };
    const queryString = new URLSearchParams(params).toString();
    dispatch(
      pushLocation({
        pathname: paths.top.path,
        search: queryString,
      }),
    );
  };

  return (
    <div class={props.className} data-testid="searchForm-wrapper">
      <div class={cx(styles.filterContainer, onPeriodOptionDisplayed && styles.periodOpened)}>
        {props.selectedTab !== Status.UPCOMING && (
          <StyledSelectBox
            name="period"
            customStyle="popup"
            className={styles.filterButton}
            displayBoxClassName={styles.selectBox}
            listClassName={popupWrapper}
            optionItemClassName={optionItem}
            optionItemSelectedClassName={selectedOptionItem}
            dropdownIcon={<DropdownArrow className={styles.dropdownIcon} size={16} />}
            options={periods}
            value={selectedPeriod}
            onChange={onClickSearch}
            onAppearanceChanged={isOpen => setOnPeriodOptionDisplayed(isOpen)}
          />
        )}
        {SERVICES.map(service => (
          <FlatButton
            classType="flat"
            data-testid={`searchForm-filter-button-${service.value}`}
            className={cx(
              styles.filterButton,
              selectedService.includes(service.value) && styles.active,
            )}
            key={service.value}
            isDisabled={isFetching}
            onClick={() => {
              let serviceType: Array<ServiceType> = selectedService;
              if (!isNotEmptyArray(serviceType)) {
                serviceType = [service.value];
              } else if (serviceType.includes(service.value)) {
                const index = serviceType.indexOf(service.value);
                serviceType.splice(index, 1);
              } else {
                serviceType.push(service.value);
              }
              handlePushLocation({ serviceType: serviceType, period: selectedPeriod });
            }}
          >
            {service.text}
          </FlatButton>
        ))}
      </div>
    </div>
  );
}

export default SearchForm;
