import React, { useEffect, useRef } from 'react';

import { cx } from '@travel/utils';

import styles from './popup.module.scss';

type Props = {
  className?: string;

  /** Using this reference to prevent closing dialog on click anchor component */
  anchorEl?: HTMLElement | null;

  /** If true, the DropdownDialog is open. */
  isOpen: boolean;

  /** If true, triangle is shown */
  isShowTriangle?: boolean;

  /** Callback function fired when the dialog requests to be closed. */
  onClose: () => void;

  /** Callback function fired when the dialog requests to be done. */
  onDone?: () => void;

  /** Custom inline-style for wrapper. */
  style?: React.CSSProperties;

  children?: React.ReactNode;
  /** the direction when most of the menu will land DEFAULT = 'left'*/
  openTo?: 'extremeLeft' | 'left' | 'middle' | 'right';

  /** Callback function fired when mouse enters the popup */
  onMouseEnter?: () => void;

  /** Callback function fired when mouse exits the popup */
  onMouseLeave?: () => void;

  /** flag to disable close by outside click  */
  isClosingByOutSideClicking?: boolean;
};

function Popup(props: Props) {
  const {
    className,
    isOpen,
    isShowTriangle,
    onClose,
    children,
    anchorEl,
    openTo = 'left',
    isClosingByOutSideClicking = true,
    ...rest
  } = props;

  const divEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClose(event: MouseEvent) {
      if (!divEl.current || !event.target) return;
      if (divEl.current.contains(event.target as Node)) return;
      // prevent closing pop-up when anchor element is clicked
      if (anchorEl && anchorEl.contains(event.target as Node)) return;

      onClose();
    }

    if (isOpen && isClosingByOutSideClicking) {
      document.addEventListener('click', handleClose);
    }

    return () => {
      if (isOpen && isClosingByOutSideClicking) {
        document.removeEventListener('click', handleClose);
      }
    };
  }, [anchorEl, isOpen, onClose, isClosingByOutSideClicking]);

  return isOpen ? (
    <div ref={divEl} className={cx(styles.popup, className)} data-testid="popup-wrapper" {...rest}>
      {isShowTriangle && <div className={cx(styles.triangle, styles[openTo])}></div>}
      {children}
    </div>
  ) : null;
}

export default Popup;
