import { h } from 'preact';

import { PaymentMethod } from '@travel/icons/service';
import {
  BusTicket,
  Calendar,
  CalendarCancelation,
  ContactHotel,
  ETicket,
  Receipt,
  Suitcase,
  UserReviewStar,
  Voucher,
} from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { DeviceType, DESKTOP_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';
import { as } from '@travel/utils';

import { QuickActions } from 'ReservationsList-Types';
import {
  bookAgain,
  cancel,
  changePaymentMethod,
  editReview,
  issueReceipt,
  mobilQrCode,
  postReview,
  qrCodeCheckIn,
  rmp,
  settlePayment,
  shortenDate,
  voucher,
  UrlParameter,
} from './quickActionsUrl';

const quickActionUrlGenerator: {
  [key in keyof QuickActions]: (parameter: UrlParameter) => string;
} = {
  cancelable: cancel,
  shorten: shortenDate,
  changePaymentMethod: changePaymentMethod,
  receipt: issueReceipt,
  qrCheckIn: qrCodeCheckIn,
  eTicket: mobilQrCode,
  postReview: postReview,
  editReview: editReview,
  bookAgain: bookAgain,
  paymentRequired: settlePayment,
  voucher: voucher,
  rmp: rmp,
};

const iconActions = (iconSize: number) => ({
  cancelable: <CalendarCancelation color="cilantro" size={iconSize} />,
  shorten: <Calendar color="cilantro" size={iconSize} />,
  changePaymentMethod: <PaymentMethod color="cilantro" size={iconSize} />,
  paymentRequired: <PaymentMethod color="cilantro" size={iconSize} />,
  qrCheckIn: <ETicket color="cilantro" size={iconSize} />,
  eTicket: <BusTicket color="cilantro" size={iconSize} />,
  receipt: <Receipt color="cilantro" size={iconSize} />,
  voucher: <Voucher color="cilantro" size={iconSize} />,
  postReview: <UserReviewStar color="cilantro" size={iconSize} />,
  editReview: <UserReviewStar color="cilantro" size={iconSize} />,
  bookAgain: <Suitcase color="cilantro" size={iconSize} />,
  rmp: <ContactHotel color="cilantro" size={iconSize} />,
});

type QuickActionButtons = Array<{
  url: string;
  icon: JSX.Element;
  key: keyof QuickActions;
  text: string;
  isNewTab: boolean;
  isQrCode: boolean;
}>;

const quickActionsLabel = {
  detail: '',
  cancelable: <Translate id="Booking_List.Reservation_Summary.Navigation.Cancel" />,
  shorten: <Translate id="Booking_List.Reservation_Summary.Navigation.Modify" />,
  changePaymentMethod: (
    <Translate id="Booking_List.Reservation_Summary.Navigation.Change_Payment" />
  ),
  paymentRequired: <Translate id="Booking_List.Reservation_Summary.Navigation.Settle_Payment" />,
  qrCheckIn: <Translate id="Booking_List.Reservation_Summary.Navigation.QR_Checkin" />,
  eTicket: <Translate id="Booking_List.Reservation_Summary.Navigation.E_Ticket" />,
  receipt: <Translate id="Booking_List.Reservation_Summary.Navigation.Issue_Receipt" />,
  voucher: <Translate id="Booking_List.Reservation_Summary.Navigation.See_Voucher" />,
  postReview: <Translate id="Booking_List.Reservation_Summary.Navigation.Write_Review" />,
  editReview: <Translate id="Booking_List.Reservation_Summary.Navigation.Edit_Review" />,
  bookAgain: <Translate id="Booking_List.Reservation_Summary.Navigation.Book_Again" />,
  rmp: <Translate id="Booking_List.Reservation_Summary.Navigation.Contact_To_Hotel" />,
};

export const formatQuickActions = (
  quickActions: QuickActions,
  parameter: UrlParameter,
  deviceType: DeviceType,
): QuickActionButtons => {
  const iconSize = deviceType === DESKTOP_SCREEN ? 21 : 32;

  return Object.keys(quickActions).reduce((result: QuickActionButtons, action) => {
    if (!quickActions[action] || (deviceType === DESKTOP_SCREEN && action === 'eTicket'))
      return result;
    const isNewTab = action === 'rmp';
    const isQrCode = action === 'qrCheckIn';

    return [
      ...result,
      {
        url: quickActionUrlGenerator[action](parameter),
        icon: iconActions(iconSize)[action],
        key: as<keyof QuickActions>(action),
        text: quickActionsLabel[action],
        isNewTab,
        isQrCode,
      },
    ];
  }, []);
};
