import { h, ComponentChild } from 'preact';

import cx from '@travel/icons/_utils/classnames';

import styles from './reservationInfo.module.scss';

type Props = {
  title: JSX.Element | string;
  content: ComponentChild;
  className?: string;
  headerClassName?: string;
};

const ReservationInfo = (props: Props) => {
  const { title, content, className, headerClassName } = props;
  return (
    <div class={cx(styles.container, className)}>
      <h4 class={cx(styles.header, headerClassName)}>{title}</h4>
      <span>{content}</span>
    </div>
  );
};

export default ReservationInfo;
